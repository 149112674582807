import ID from './ID'
import PlanDashboard from './PlanDashboard'

export default class PlanDashboardCategory {
  constructor(
    public id: ID = new ID(),
    public name: string = '',
    public sortOrder: number = 0,

    public dashboards: PlanDashboard[] = [], // Populated on FE
  ) {}

  public get key() {
    return 'plan-dashboard-category-' + this.id.intID.toString()
  }

  public get activeDashboards(): PlanDashboard[] {
    return this.dashboards.filter((dashboard) => dashboard.isArchived === false).sort((a, b) => a.sortOrder - b.sortOrder)
  }

  public get archivedDashboards(): PlanDashboard[] {
    return this.dashboards.filter((dashboard) => dashboard.isArchived === true).sort((a, b) => a.sortOrder - b.sortOrder)
  }

  public static fromResponseObject(obj): PlanDashboardCategory {
    if (!obj) return new PlanDashboardCategory()
    return new PlanDashboardCategory(
      ID.fromResponseObject(obj.id, 'plan_dashboard_categories'),
      obj.name,
      obj.orderIndex,
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      name: this.name,
      orderIndex: this.sortOrder,
    }
  }
}
