var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"view-wrapper"},[_c('ViewHeader',{attrs:{"title":"Tags","isFullViewport":true,"hideSectionHomeButton":true,"hideRoleTutorial":true},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('Button',{staticClass:"p-button-outlined p-ml-2",attrs:{"label":"Close"},on:{"click":()=>{
          _vm.$emit('complete')
          _vm.$router.back()
        }}})]},proxy:true}])}),_c('ViewMain',{attrs:{"isFullViewport":true}},[_c('div',{staticClass:"content plan-settings-modal-content",staticStyle:{"max-width":"100rem","margin":"0 auto"}},[(_vm.loading)?_c('div',[_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v("Loading...")])]):_c('div',[_c('div',{staticClass:"p-field"},[_c('div',{staticClass:"p-d-flex top-items"},[_c('p',{staticClass:"plan-settings-instructions"},[_vm._v(" Tags are a simple way to classify Initiatives and Tactics in order to create segmented cross-channel views. Our default categories of Tags include objectives, target segments, and journey phases. Custom categories can be created for business units, regions, content pillars, etc."),_c('br'),_c('br'),_vm._v(" Set up your Tags, then tag Initiatives and Tactics, and use Filters to create isolated views by Tag. View "),_c('a',{staticClass:"text-link",attrs:{"target":"_blank","href":"https://www.annumplanning.com/resources/guides/tags/"}},[_vm._v("detailed instructions")]),_vm._v(". ")])])]),_c('HowItWorks',{staticClass:"p-mb-6",attrs:{"imagePath":"how-it-works-strategic-priorities.jpg","slideshowPath":"https://docs.google.com/presentation/d/e/2PACX-1vREe1PDII_06MfJjSKd3bIxHJFvdGulJcwiWLGfVp_qElacJkKoy20ZtzUYhIiCIRIwRNl4jKLvmp-G/embed?start=false&loop=true&delayms=5000","hideOverlay":true}}),_vm._l((_vm.tagCategories),function(category){return _c('div',{key:category.id,staticClass:"category plan-category"},[_c('div',{staticClass:"category__title"},[_c('h3',[_vm._v(_vm._s(category.name))])]),_c('Container',{staticClass:"category__tags",attrs:{"drag-handle-selector":".tag__drag-icon"},on:{"drop":(dropResults) => _vm.onTagDrop(dropResults, category)}},_vm._l((category.tags),function(tag,i){return _c('Draggable',{key:`tag-${tag.id.intID}-${i}`,class:{
                'tag': true,
                'is-nested': tag.isNested,
                'is-lead': tag.isLead,
              }},[(_vm.areTagsReorderable)?_c('p',{staticClass:"tag__drag-icon"},[_vm._v("☰")]):_vm._e(),(!tag.editing)?_c('p',{staticClass:"tag__title"},[(
                    _vm.isLeadPlan && tag.isNested && tag.abbreviatedPlanName !== ''
                  )?[_vm._v(" "+_vm._s(tag.abbreviatedPlanName)+" > "+_vm._s(tag.title)+" ")]:[_vm._v(" "+_vm._s(tag.title)+" ")],(_vm.$store.getters.currentUser.isSuperUser)?[_c('span',{staticClass:"id-value"},[_vm._v(" ("+_vm._s(tag.id.intID)+")")])]:_vm._e()],2):_c('input',{directives:[{name:"model",rawName:"v-model",value:(tag.editingValues.name),expression:"tag.editingValues.name"}],staticClass:"tag__edit-title",attrs:{"type":"text"},domProps:{"value":(tag.editingValues.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(tag.editingValues, "name", $event.target.value)}}}),(_vm.getIsTagEditable(tag))?_c('div',{staticClass:"tag__icons"},[(!tag.editing)?_c('span',[_c('i',{staticClass:"pi pi-pencil",on:{"click":() => {
                        tag.editing = true
                      }}}),_c('i',{staticClass:"pi pi-trash delete-icon",on:{"click":(e) => _vm.handleDeleteTag(e, tag.id.intID)}})]):_c('span',[_c('i',{staticClass:"pi pi-check",on:{"click":() => _vm.handleTagEdit(category.name, tag)}}),_c('i',{staticClass:"pi pi-times",on:{"click":() => {
                        tag.editing = false
                      }}}),_c('i',{staticClass:"pi pi-trash delete-icon",on:{"click":(e) => _vm.handleDeleteTag(e, tag.id.intID)}})])]):_vm._e()])}),1)],1)}),_c('div',{staticClass:"new-tag p-d-flex p-jc-center"},[_c('div',{staticClass:"new-tag__category"},[_c('div',{staticClass:"p-mb-1"},[_c('label',{attrs:{"for":"newTagCategory"}},[_vm._v("Category")])]),_c('Dropdown',{attrs:{"options":_vm.tagCategoryOptions,"optionLabel":"name","OrderBY":"id","optionValue":"name","name":"newTagCategory"},on:{"change":_vm.handleTagSelect},model:{value:(_vm.newTag.category),callback:function ($$v) {_vm.$set(_vm.newTag, "category", $$v)},expression:"newTag.category"}})],1),(_vm.newTag.category === '[ New Category ]')?_c('div',{staticClass:"new-tag__name"},[_c('div',{staticClass:"p-mb-1"},[_c('label',{attrs:{"for":"newTagName"}},[_vm._v("New Category Name")])]),_c('InputText',{attrs:{"type":"text","name":"newTagName"},model:{value:(_vm.newTag.categoryName),callback:function ($$v) {_vm.$set(_vm.newTag, "categoryName", $$v)},expression:"newTag.categoryName"}})],1):_vm._e(),_c('div',{staticClass:"new-tag__name"},[_c('div',{staticClass:"p-mb-1"},[_c('label',{attrs:{"for":"newTagName"}},[_vm._v("Tag Name")])]),_c('InputText',{attrs:{"name":"newTagName"},model:{value:(_vm.newTag.name),callback:function ($$v) {_vm.$set(_vm.newTag, "name", $$v)},expression:"newTag.name"}})],1),_c('div',{staticClass:"new-tag__add",on:{"click":_vm.handleAddTag}},[_c('i',{staticClass:"pi pi-plus-circle p-mr-1"}),_vm._v(" Tag ")])])],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }