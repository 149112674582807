<template>
  <div class="view-wrapper performance-view">
    <ViewHeader :title="$store.getters.currentPlan.name">
    </ViewHeader>
    <ViewMain>
      <template v-if="doesPlanHaveActiveDashboards">
        <div class="p-d-flex">
          <div class="list-column">
            <div class="active-section">
              <template v-for="category in planDashboardCategories">
                <template v-if="category.activeDashboards.length">
                  <DataTable 
                    :value="category.activeDashboards"
                    editMode="row"
                    dataKey="id.intID"
                    :key="category.key"
                    class="datatable-active"
                    >
                    <Column field="title" :header="category.name">
                      <template #body="slotProps">
                        {{ slotProps.data.name }}
                      </template>
                    </Column>
                    <Column>
                      <template #body="slotProps">
                        <div class="p-d-flex p-jc-end p-ai-center">
                          <template v-if="slotProps.data.id.intID === currentDashboard.id.intID">
                            <div class="current-dashboard-indicator"></div>
                          </template>
                          <template v-else>
                            <Button 
                              icon="pi pi-arrow-circle-up" 
                              class="p-button-text"
                              @click="setCurrentDashboard(slotProps.data)"
                            ></Button>
                          </template>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </template>
              </template>
            </div>
          </div>
          <div class="dashboard-column">
            <h2 v-if="currentDashboard.name" class="p-mr-2">{{ currentDashboard.name }}</h2>
            <div class="embed-wrapper">
              <ProgressSpinner v-if="shouldShowProgressSpinner" strokeWidth="3" class="dashboard-progress-spinner" />
              <template v-if="currentDashboard.embedHtml">
                <div class="embed-container" v-html="currentDashboard.embedHtml"></div>
              </template>
              <template v-else>
                <div class="embed-container">
                  <iframe :title="currentDashboard.name" :src="currentDashboard.asset?.fileLocation" width="100%" height="100%" style="border: 0; height: 80vh; height: calc(100vh - 17rem);"></iframe>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no-data-container p-d-flex p-d-flex-column p-ai-center p-jc-center">
          <h2 class="no-data-message">To see performance data in this view, an Admin must embed the dashboard or report under Plan Settings.</h2>
        </div>
      </template>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ViewHeader from '@/components/ViewHeader.vue';
import ViewMain from '@/components/ViewMain.vue';
import PlanDashboard from '@/models/PlanDashboard';
import DataTable from 'primevue/datatable/DataTable';
import Column from 'primevue/column/Column';
import Button from 'primevue/button';
import Plan from '@/models/Plan';
import PlanDashboardCategory from '@/models/PlanDashboardCategory';


Vue.component('Button', Button)
Vue.component('DataTable', DataTable)

export default Vue.extend({
  name: 'Performance',
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      currentDashboard: {} as PlanDashboard,
      shouldShowProgressSpinner: false,
    }
  },
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    planDashboardCategories(): PlanDashboardCategory[] {
      return [...this.currentPlan.planDashboardCategories].sort((a, b) => a.sortOrder - b.sortOrder)
    },
    doesPlanHaveActiveDashboards(): boolean {
      return this.planDashboardCategories.some((category) => category.activeDashboards.length > 0)
    },
  },
  mounted: function(){
    this.resetCurrentDashboard()
  },
  methods: {
    resetCurrentDashboard() {
      if(this.planDashboardCategories.length){
        this.planDashboardCategories.every((category) => {
          if(category.activeDashboards.length){
            this.setCurrentDashboard(category.activeDashboards[0])
            return false
          }
        })
      }else{
        this.currentDashboard = {} as PlanDashboard
        this.shouldShowProgressSpinner = false
      }
    },
    setCurrentDashboard(dashboard) {
      this.shouldShowProgressSpinner = true
      this.currentDashboard = dashboard as PlanDashboard
    },
  }
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.performance-view {
  .no-data-container {
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .no-data-message {
    color: $errorColor;
    font-weight: 300;
    font-variation-settings: 'wght' 300;
  }
  .p-datatable {
    margin-bottom: 3.6rem;

    .p-datatable-thead {
      &> tr {
        &> th {
          padding: 1rem;
          font-size: 1.3rem;
        }
      }
    }
    .p-datatable-tbody {
      &> tr {
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.08);

        &> td {
          padding: 1rem;
          border: 0;
          font-size: 1.2rem;
        }
      }
    }
  }
}
.list-column {
  width: 25rem;
  margin-right: 3rem;

  .is-archived {
    color: $nobel;
  }
  &> p {
    color: #999999;
  }
}
.dashboard-column {
  width: calc(100% - 28rem);
  overflow: auto;
}
.current-dashboard-indicator {
  width: 1.9rem;
  height: 1.9rem;
  margin: 0.65rem 0.4rem 0.65rem 0;
  border-radius: 50%;
  background-color: #008001;
}
.embed-wrapper {
  position: relative;

  .dashboard-progress-spinner {
    position: absolute;
  }
  .embed-container {
    position: relative;
    z-index: 2;
  }
}
</style>
