<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Initiatives"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
        @click="()=>{
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="p-p-4">
        <div class="intro-container">
          <div>
            <p class="plan-settings-instructions">
              Initiatives are strategic efforts that have a specific duration and include multiple connected Tactics. They are categorized as campaigns, promotions, key dates, or monthly themes.<br/><br/>
              Enter Initiatives, then connect Tactics, and use Filters to create isolated views for cross-channel optimization. View <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/plan-initiatives/">detailed instructions</a>.
            </p>
          </div>
          <HowItWorks 
            class="p-mb-6"
            imagePath="how-it-works-initiatives.jpg" 
            slideshowPath="https://docs.google.com/presentation/d/e/2PACX-1vSQZBPeymCp6k9IOUmg_EwZk_GqZ0IkvWgpA6heN5ZEDJ0WmFBFgQmR2ZCz3dlXZHK7uXAK2u1rD8O3/embed?start=false&loop=true&delayms=5000"
            :hideOverlay="true"
            />
        </div>
        <div class="p-d-flex p-ai-end">
          <div class="date-range-container p-mb-3">
            <div class="range p-d-flex">
              <div class="p-field p-mr-2">
                <label for="startDate">Start Date</label>
                <Calendar
                  id="startDate"
                  v-model="dateRangeStart"
                  :manualInput="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="$store.getters.datePickerYearRange"
                  :showIcon="true"
                  selectionMode="single"
                  dateFormat="mm/dd/yy"
                />
              </div>
              <div class="p-field">
                <label for="endDate">End Date</label>
                <Calendar
                  id="endDate"
                  v-model="dateRangeEnd"
                  :manualInput="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="$store.getters.datePickerYearRange"
                  :showIcon="true"
                  selectionMode="single"
                  dateFormat="mm/dd/yy"
                />
              </div>
            </div>
          </div>
          <div class="p-field p-ml-2 p-mb-5">
            <label for="channels">Category</label>
            <Dropdown
              id="channels"
              v-model="selectedCategory"
              :options="categoryOptions"
              optionLabel="name"
              dataKey="key"
              :showClear="selectedCategory && selectedCategory.name ? true : false"
              placeholder="Choose Category"
              @change="()=>{
                // Clear selected sort when clearing selection, sort by start date after making selection
                if(selectedCategory && selectedCategory.name){
                  currentInitiativesTableSortField = 'startDate'
                }else{
                  currentInitiativesTableSortField = ''
                }
              }"
            />
          </div>
          <Button
            v-if="canUserManagePlanUsersChannelsSubscriptions"
            label="Add Initiative"
            icon="pi pi-plus-circle"
            class="p-ml-auto p-mb-5"
            @click="handleAddInitiative"
          />
        </div>

        <DataTable
          v-model="filteredInitiatives"
          :autoLayout="true"
          class="initiatives-table"
          :sortField="currentInitiativesTableSortField"
          :sortOrder="1"
          removableSort
        >
          <Column
            field="title"
            header="Topic"
            headerClass="title-column"
            bodyClass="title-column"
            sortable
          >
            <template #body="slotProps">
              <span
                :class="{
                  'is-nested': slotProps.data.isNested,
                  'is-lead': slotProps.data.isLead,
                }"
              >
                {{ getTitleForTactic(slotProps.data) }}
              </span>
              <template v-if="$store.getters.currentUser.isSuperUser">
                <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
              </template>
            </template>
          </Column>        
          <Column field="tacticTypeName" header="Category">
            <template #body="slotProps">
              {{ slotProps.data.tacticTypeName }}
            </template>
          </Column>
          <Column field="startDate" header="Start Date" sortable>
            <template #body="slotProps">
              {{ slotProps.data.startDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column field="endDate" header="End Date" sortable>
            <template #body="slotProps">
              {{ slotProps.data.endDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column header="Objective" v-if="doesPlanHaveObjectives">
            <template #body="slotProps">
              <template v-if="getObjectivesForTactic(slotProps.data).length">
                <template
                  v-for="(tag, index) in getObjectivesForTactic(slotProps.data)"
                >
                  <span class="tag-title" :key="index">
                    {{ tag.title }}
                    <template v-if="index + 1 < getObjectivesForTactic(slotProps.data).length">
                      &nbsp;/&nbsp;
                    </template>
                  </span>
                </template>
              </template>
            </template>
          </Column>
          <Column header="Target Segment" v-if="doesPlanHaveTargetSegments">
            <template #body="slotProps">
              <template v-if="getTargetSegmentsForTactic(slotProps.data).length">
                <template
                  v-for="(tag, index) in getTargetSegmentsForTactic(
                    slotProps.data
                  )"
                >
                  <span class="tag-title" :key="index">
                    {{ tag.title }}
                    <template v-if="index + 1 < getTargetSegmentsForTactic(slotProps.data).length">
                      &nbsp;/&nbsp;
                    </template>
                  </span>
                </template>
              </template>
            </template>
          </Column>
          <Column header="Journey Phase" v-if="doesPlanHaveJourneyPhases">
            <template #body="slotProps">
              <template v-if="getJourneyPhasesForTactic(slotProps.data).length">
                <template
                  v-for="(tag, index) in getJourneyPhasesForTactic(
                    slotProps.data
                  )"
                >
                  <span class="tag-title" :key="index">
                    {{ tag.title }}
                    <template v-if="index + 1 < getJourneyPhasesForTactic(slotProps.data).length">
                      &nbsp;/&nbsp;
                    </template>
                  </span>
                </template>
              </template>
            </template>
          </Column>
          <Column header="Notes" key="summary">
            <template #body="slotProps">
              <Button
                  v-if="slotProps.data.summary !== null && slotProps.data.summary !== ''"
                  icon="pi pi-eye"
                  class="p-button-icon-only p-button-rounded p-button-text"
                  @mouseenter="(event)=> {
                    currentOverlayHTML = slotProps.data.summary ? slotProps.data.summary : ''
                    $refs.richTextFieldOverlay.show(event)
                  }"
                  @mouseleave="(event)=> {
                    $refs.richTextFieldOverlay.hide()
                  }"
                ></Button>
            </template>
          </Column>
          <Column header="Documents" key="documents">
            <template #body="slotProps">
              <div class="p-d-flex p-jc-center">
                <span v-if="slotProps.data.documents.length" class="pi pi-paperclip"></span>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div
                class="p-d-flex p-jc-end"
                v-if="
                  (isLeadPlan && slotProps.data.isLead) ||
                  (!isLeadPlan && !slotProps.data.isLead)
                "
              >
                <Button
                  label="Open"
                  icon="pi pi-arrow-circle-up"
                  class="p-button-text"
                  @click="showTactic(slotProps.data)"
                ></Button>
              </div>
            </template>
          </Column>
          <template #empty>
            <h3>No initiatives found for the given date range and category.</h3>
          </template>
        </DataTable>

        <div class="categories-container" style="max-width: 60rem; margin: 0 auto;">
          <DataTable
            :editingRows.sync="categoryEditingRows" 
            :value="categoryOptions" 
            editMode="row" 
            dataKey="key" 
            :autoLayout="true"
            class="categories-table"
            @row-edit-init="onCategotyRowEditInit"
            @row-edit-save="onCategotyRowEditSave"
            @row-reorder="onCategoryRowReorder"
          >
            <Header>
              <h2>Categories of Initiatives</h2>
            </Header>
            <Column 
              rowReorder 
              headerStyle="width: 3rem" 
              v-if="canUserEditPlanProperties"
              />
            <Column field="name" bodyStyle="width: 80%;">
              <template #body="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <div>
                    {{ slotProps.data.name }}
                    <template v-if="$store.getters.currentUser.isSuperUser">
                      <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
                    </template>
                  </div>
                  <div v-if="slotProps.data.name.toLowerCase() === $store.getters.initiativeTypeNamesDict.monthlyTheme" style="font-size: 0.9rem;">
                    Duration by Month only
                  </div>
                </div>
              </template>
              <template #editor="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <InputText v-model="slotProps.data.name" />
                  <div v-if="slotProps.data.name.toLowerCase() === $store.getters.initiativeTypeNamesDict.monthlyTheme" style="font-size: 0.9rem;">
                    Duration by Month only
                  </div>
                </div>
              </template>
            </Column>
            <Column 
              :rowEditor="true" 
              bodyStyle="text-align: right; width: 10%;"
              v-if="canUserEditPlanProperties"
            ></Column>
            <Column 
              bodyStyle="text-align: left; width: 10%;"
              v-if="canUserEditPlanProperties"
            >
              <template #body="slotProps">
                <Button 
                  icon="pi pi-trash" 
                  severity="danger" 
                  class="p-button-text p-button-danger"
                  @click="onCategoryDelete($event, slotProps.data)" 
                />
              </template>
              <template #editor>
                <Button 
                  icon="pi pi-trash" 
                  severity="danger" 
                  class="p-button-text p-button-danger"
                  disabled
                />
              </template>
            </Column>
          </DataTable>

          <div class="add-initiative-category-container" v-if="canUserEditPlanProperties">
            <div class="p-field">
              <label for="add-initiative-category-input">New Category Name </label>
              <InputText v-model="newCategoryName" />
            </div>
            <Button 
              label="New Initiative Category"
              icon="pi pi-plus-circle" 
              class="p-button-text"
              @click="addCategory()" 
            />
          </div>
        </div>

        </div>

        <OverlayPanel ref="richTextFieldOverlay" class="rich-text-field-overlay" appendTo="body">
          <div class="notes-field-content-container" v-html="currentOverlayHTML"></div>
        </OverlayPanel>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Plan from '@/models/Plan'
import Role from '@/models/Role'
import Channel from '@/models/Channel'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Tactic from '@/models/Tactic'
import TacticType from '@/models/TacticType'
import HowItWorks from '@/components/HowItWorks.vue'
import Tag from '@/models/Tag'

export default Vue.extend({
  name: 'PlanSettingsInitiatives',
  components: {
    ViewHeader,
    ViewMain,
    HowItWorks,
  },
  data: () => ({
    fileTypes: '.jpg,.jpeg,.png,.gif,.webp' as string,
    topViewContent: {
      title: Channel.CHANNEL_NAME_INITIATIVES,
      infoText:
        'Enter priority Initiatives to view in the plan and connect related Tactics.',
    },
    shouldShowTagList: false,
    newTacticNames: {},
    newTacticName: '',
    newTacticCategory: '',
    selectedCategory: {} as TacticType,
    currentOverlayHTML: '' as string,
    currentInitiativesTableSortField: 'tacticTypeName' as string,
    categoryEditingRows: [],
    newCategoryName: '',
  }),
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    initiativeChannel(): Channel {
      return this.$store.getters.currentPlan.consolidatedInitiativeChannel
    },
    categoryOptions(): TacticType[] {
      return this.initiativeChannel.tacticTypes
    },
    isLeadPlan(): boolean {
      return this.plan.plans.length ? true : false
    },
    canUserEditPlanProperties(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserManagePlanUsersChannelsSubscriptions(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
    dateRangeStart: {
      get(): Date {
        return this.$store.getters.initiativesDateRange[0]
      },
      set(newDate: Date) {
        newDate.setHours(0,0,0,0)
        this.$store.dispatch('updateInitiativesDateRange', [newDate, this.$store.getters.initiativesDateRange[1]])

        if(this.$store.getters.initiativesDateRange[0] > this.$store.getters.initiativesDateRange[1]){
          const newEndDate = new Date(this.$store.getters.initiativesDateRange[0].getTime())
          newEndDate.setDate(this.$store.getters.initiativesDateRange[0].getDate() + 30)
          this.dateRangeEnd = newEndDate
        }
      },
    },
    dateRangeEnd: {
      get(): Date {
        return this.$store.getters.initiativesDateRange[1]
      },
      set(newDate: Date) {
        newDate.setHours(23, 59, 59, 999)
        this.$store.dispatch('updateInitiativesDateRange', [this.$store.getters.initiativesDateRange[0], newDate])

        if(this.$store.getters.initiativesDateRange[0] > this.$store.getters.initiativesDateRange[1]){
          const newStartDate = new Date(this.$store.getters.initiativesDateRange[1].getTime())
          newStartDate.setDate(this.$store.getters.initiativesDateRange[0].getDate() - 30)
          this.dateRangeStart = newStartDate
        }
      },
    },
    filteredInitiatives(): Tactic[] {
      return this.$store.getters.currentInitiatives.filter((tactic) => 
        !(
          tactic.endDate < this.dateRangeStart || 
          tactic.startDate > this.dateRangeEnd
        ) && (
          this.selectedCategory && 
          this.selectedCategory.name ? tactic.tacticTypeName === this.selectedCategory.name : true
        )
      )
    },
    doesPlanHaveObjectives(): boolean {
      return this.$store.getters.currentTags.filter((tag) => tag.isObjective)
        .length
        ? true
        : false
    },
    doesPlanHaveTargetSegments(): boolean {
      return this.$store.getters.currentTags.filter(
        (tag) => tag.isTargetSegment
      ).length
        ? true
        : false
    },
    doesPlanHaveJourneyPhases(): boolean {
      return this.$store.getters.currentTags.filter((tag) => tag.isJourneyPhase)
        .length
        ? true
        : false
    },
  },
  methods: {
    onCategoryRowReorder(event){
      // Update values
      event.value.forEach((type, i)=>{
        type.orderIndex = i
      })
      this.initiativeChannel.tacticTypes = this.initiativeChannel.tacticTypes.sort((a,b) => a.orderIndex - b.orderIndex)

      // Persist order values to server
      this.initiativeChannel.tacticTypes.forEach((type) => {
        this.$store.getters.services.channels.updateTacticType(type) // TODO: reduce number of requests by updating all types with one request
      })
    },
    onCategotyRowEditInit() {
      // Init row edit
    },
    onCategotyRowEditSave(event){
      const newTacticTypeName = event.newData.name
      const thisTacticType = event.data as TacticType

      // Create an array of other TacticTypes with the same name
      let matchingNameTacticTypes = [] as TacticType[]
      this.$store.getters.currentPlan.allInitiativeChannels.forEach((channel)=>{
        matchingNameTacticTypes = matchingNameTacticTypes.concat(channel.tacticTypes.filter((checkType) => checkType.id.intID !== event.data.id.intID && checkType.name.toLowerCase() === event.data.name.toLowerCase()))
      })

      // Update name on tactic type object
      thisTacticType.name = newTacticTypeName

      // Create array of promizez for TacticTypes to update
      const allPromises = [
        new Promise((resolve, reject) => {
          this.$store.getters.services.channels.updateTacticType(thisTacticType).then(
            (response)=>{
              resolve(response)
            },
            (error)=>{
              reject(error)
            }
          )
        })
      ]
      matchingNameTacticTypes.forEach((type)=>{
        type.name = newTacticTypeName
        allPromises.push(
          new Promise((resolve, reject) => {
            this.$store.getters.services.channels.updateTacticType(type).then(
              (response)=>{
                resolve(response)
              },
              (error)=>{
                reject(error)
              }
            )
          })
        )
      })

      Promise.all(allPromises).then((values) => {
          this.$store.dispatch('refreshCurrentPlan')
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Initiative category updated.',
            life: 3000,
          })
        })
        .catch((error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary:
              'There was an error updating the initiative category. Please try again.',
          })
        })
    },
    onCategoryDelete(event, category: TacticType){
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure you want to delete this Category of Initiatives? All Initiatives within this Category will be deleted along with it.',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels.deleteTacticType(category.id.intID).then(
            ()=>{
              this.$store.dispatch('refreshCurrentPlan')

              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: 'Initiative category deleted.',
                life: 3000,
              })
            },
            (error)=>{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary:
                  'There was an error deleting the initiative category. Please try again.',
              })
            }
          )
        },
        reject: () => {
          //Delete rejected
        },
      })
    },
    addCategory(){
      if(this.newCategoryName === ''){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please add a name for the new category.',
          life: 3000,
        })
        return
      }
      
      const newTacticType = new TacticType()
      newTacticType.name = this.newCategoryName
      newTacticType.channelId = this.initiativeChannel.id
      newTacticType.orderIndex = this.categoryOptions.length

      this.$store.getters.services.channels.createTacticType(newTacticType).then(
        (newTacticType)=>{
          this.newCategoryName = ''
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Initiative category created.',
            life: 3000,
          })
        },
        (error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary:
              'There was an error creating the initiative category. Please try again.',
          })
        }
      )
    },
    closeModal() {
      this.$emit('complete')
    },
    handleAddInitiative(id) {
      const targetIntID = id.intID ? id.intID : 0
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/initiative/${targetIntID}?datetime=${this.dateRangeStart}`,
      })
    },
    getTitleForTactic(tactic: Tactic) {
      let returnString = tactic.title
      if (this.isLeadPlan && tactic.abbreviatedPlanName !== '') {
        if (returnString.length > 0) {
          returnString = tactic.abbreviatedPlanName + ' > ' + returnString
        } else {
          returnString = tactic.abbreviatedPlanName
        }
      }
      return returnString
    },
    showTactic: function (tactic: Tactic) {
      const thisTacticIntID = tactic.originalTacticID ? tactic.originalTacticID.intID : tactic.id.intID
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/initiative/${thisTacticIntID}`,
      })
    },
    getObjectivesForTactic: function (tactic: Tactic): Tag[] {
      return tactic.tags.filter((tag) => tag.isObjective)
    },
    getTargetSegmentsForTactic: function (tactic: Tactic): Tag[] {
      return tactic.tags.filter((tag) => tag.isTargetSegment)
    },
    getJourneyPhasesForTactic: function (tactic: Tactic): Tag[] {
      return tactic.tags.filter((tag) => tag.isJourneyPhase)
    },
  },
})
</script>