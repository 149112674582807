import ID from './ID'
import MediaAsset from './MediaAsset'
import PlanDashboardCategory from './PlanDashboardCategory'

export default class PlanDashboard {
  constructor(
    public id: ID = new ID(),
    public name: string = '',
    public embedHtml: string = '',
    public asset: MediaAsset | null = null,
    public isArchived: boolean = false,
    public plan: ID = new ID(),
    public category: PlanDashboardCategory | null = new PlanDashboardCategory(),
    public sortOrder: number = 0,
    public newFile: any | null = null,
  ) {}

  public get key() {
    return 'plan-dashboard-' + this.id.intID.toString()
  }

  public static fromResponseObject(obj): PlanDashboard {
    if (!obj) return new PlanDashboard()
    return new PlanDashboard(
      ID.fromResponseObject(obj.id, 'plan_dashboards'),
      obj.name,
      obj.embedHtml,
      obj.asset ? MediaAsset.fromResponseObject(obj.asset) : null,
      obj.isArchived,
      ID.fromResponseObject(obj.plan, 'plans'),
      obj.category ? PlanDashboardCategory.fromResponseObject(obj.category) : null,
      obj.orderIndex,
      obj.newFile,
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      name: this.name,
      embedHtml: this.embedHtml,
      asset: this.asset?.id.intID == 0 ? null : this.asset?.id.apiID,
      isArchived: this.isArchived,
      plan: this.plan.apiID,
      orderIndex: this.sortOrder,
      category: this.category?.id.intID == 0 ? null : this.category?.id.apiID,
    }
  }
}
