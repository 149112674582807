<template>
  <div class="view-wrapper">
    <ViewHeader title="Profile" :isFullViewport="true" :isClose="true">
      <template #actions>
        <Button class="p-button-outlined p-mr-3" @click="navigationTo">close</Button>
      </template>
    </ViewHeader>
    <ViewMain :isFullViewport="true">
      <div class="user-detail-container p-mt-4">
        <div class="profile-photo-container p-mb-4" style="padding-left: 20%">
          <div class="profile-photo">
            <img
              v-if="image.id && image.id.intID !== 0"
              alt="User profile photo"
              :src="image.fileLocation"
              width="100"
              height="100"
            />
          </div>
          <div>
            <div class="upload-image" @click="fileUpload">
              <i class="pi pi-plus" />
              <label>Choose Image </label>
            </div>
            <input
              type="file"
              id="new-document"
              style="display: none"
              ref="uploadInput"
              @change="onFileUpload"
            />
          </div>
        </div>

        <div style="padding-left: 10%">
          <div class="p-field p-inputtext-lg">
            <label for="firstName">First Name</label>
            <InputText
              id="firstName"
              type="text"
              v-model="user.firstName"
              autocomplete="new-password"
            />
          </div>
          <div class="p-field p-inputtext-lg">
            <label for="lastName">Last name</label>
            <InputText
              id="lastName"
              type="text"
              v-model="user.lastName"
              autocomplete="new-password"
            />
          </div>
          <div class="p-field p-inputtext-lg">
            <label for="email">Email address</label>
            <InputText
              id="email"
              type="email"
              v-model="user.emailAddress"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="new-password"
            />
          </div>
        </div>

        <div
          class="p-mt-4"
          style="padding-left: 5%; margin-top: 3.5rem !important"
        >
          <label for="newPassword">Change Password</label>
          <div class="p-field p-inputtext-lg p-mt-4"></div>

          <form class="p-field p-inputtext-lg p-mt-4">
            <label for="newPassword">New Password</label>
            <Password
              id="newPassword"
              :feedback="true"
              v-model="newPassword"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="new-password"
            />
          </form>

          <form class="p-field p-inputtext-lg">
            <label for="confirmPassword">Confirm New Password</label>
            <Password
              id="confirmPassword"
              :feedback="true"
              v-model="confirmPassword"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="new-password"
            />
          </form>

          <div class="p-d-flex p-jc-end" style="margin-top: 40px">
            <Button @click="updateUser">Save</Button>
          </div>
        </div>
      </div>

      <div class="account-user-detail p-mt-4" v-if="!isUserAccountOwner">
        <DataTable
          :autoLayout="true"
          :sortOrder="1"
          :value="currentUserInArray"
          sortField="user.firstName"
        >
          <Column header="Account">
            <template #body="">
              {{ $store.getters.currentAccount.contactCompanyName }}
            </template>
          </Column>
          <Column bodyStyle="text-align: center;">
            <template #body="slotProps">
              <i
                class="pi pi-trash delete-icon"
                @click="removeUserFromAccount($event, slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="account-user-detail-role" v-if="isUserAccountOwner">
        <DataTable
          :autoLayout="true"
          :sortOrder="1"
          :value="currentUserInArray"
          sortField="user.firstName"
        >
          <Column header="Account" bodyStyle="width:40%">
            <template #body="">
              {{ $store.getters.currentAccount.contactCompanyName }}
            </template>
          </Column>
          <Column header="Account Role">
            <template #body="">
              Account Owner
            </template>
          </Column>
          <Column bodyStyle="text-align:right">
            <template #body="">
              <p>
                To transfer account ownership contact
                <a class="contact-email" href="mailto:hello@adminplanning.com"
                  >hello@adminplanning.com</a
                >
              </p>
            </template>
          </Column>
        </DataTable>
      </div>

      <ul class="team-plan">
        <li class="team-plan__head">
          <span id="firstName">Plan</span>
          <span id="role">Role </span>
          <span v-show="isCurrentUserAccountOwner" id="budget">Budget</span>
          <span id="editRights">Channel Edit Rights</span>
        </li>
        <li v-if="userRolesFromCurrentPlans.length === 0">Loading...</li>
        <li
          v-for="(userRole, i) of userRolesFromCurrentPlans"
          :key="i"
          :class="[
            userRole.parentId.intID !== 0
              ? 'team-plan__subitem'
              : 'team-plan__item',
          ]"
        >
          <div class="p-d-flex" :key="i + 1">
            <span
              id="firstName"
              :style="[
                userRole.parentId.intID !== 0 ? {'padding-left': '20px'} : '',
              ]"
            >
              <span>{{ userRole.name }} <span class="id-value"> ({{ userRole.planId.intID }})</span></span>
            </span>
            <span id="role">
              <div class="edit" v-if="userRole.isEditing">
                <Dropdown
                  :options="roles"
                  optionLabel="title"
                  optionValue="code"
                  placeholder="Select"
                  v-model="userRole.editingValues.roleId"
                  @change="onRoleChange($event, userRole)"
                />
              </div>
              <div v-else>
                <span>
                  <template v-if="isUserAccountOwner">
                    Account Owner
                  </template>
                  <template v-else>
                    {{ getRoleForId(userRole.roleId.intID) }}
                  </template>
                </span>
              </div>
            </span>
            <div v-show="isCurrentUserAccountOwner" id="budget">
              <div class="edit" v-if="userRole.isEditing">
                <Dropdown
                  :options="budgetOptions"
                  optionLabel="name"
                  optionValue="code"
                  dataKey="name"
                  v-model="userRole.canViewBudget"
                  :disabled="
                    userRole.editingValues.roleId.intID !== 5 &&
                    userRole.editingValues.roleId.intID !== 6
                  "
                />
              </div>
              <div v-else style="padding-left: 6px">
                <span v-if="userRole.canViewBudget">View</span>
                <span v-else>Hide</span>
              </div>
            </div>
            <span id="editRights">
              <div class="edit" v-if="userRole.isEditing">
                <MultiSelect
                  v-model="userRole.editingValues.channels"
                  :options="getChannelsForPlanId(userRole.planId)"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select"
                  class="mutliselect"
                  dataKey="name"
                  :disabled="userRole.editingValues.roleId.intID !== 5"
                >
                  <template #value="slotProps">
                    <template
                      v-if="
                        slotProps.value.length ===
                        getChannelsForPlanId(userRole.planId).length
                      "
                    >
                      All
                    </template>
                    <template
                      v-else-if="
                        !slotProps.value || slotProps.value.length === 0
                      "
                    >
                      Select Channel
                    </template>
                    <template v-else>
                      <div>
                        <span
                          v-for="(channelId, channelIndex) in userRole
                            .editingValues.channels"
                          :key="channelId.intID + Math.random()"
                        >
                          {{
                            getChannelForId(userRole.planId, channelId) &&
                            `${
                              getChannelForId(userRole.planId, channelId)?.name
                            }${
                              channelIndex ===
                              userRole.editingValues.channels.length - 1
                                ? ''
                                : ', '
                            }`
                          }}
                        </span>
                      </div>
                    </template>
                  </template>
                </MultiSelect>
              </div>
              <div class="channel-edit-right" v-else>
                <div v-if="userRole.canEditAllChannels === false && (userRole.channels && userRole.channels.length === 0)">
                  None
                </div>
                <div
                  v-else-if="
                    userRole.canEditAllChannels === true || 
                    (userRole.channels &&
                    userRole.channels.length >=
                      getChannelsForPlanId(userRole.planId).length)
                  "
                >
                  All
                </div>
                <div v-else>
                  <span
                    v-for="(channelId, channelIndex) in userRole.channels"
                    :key="
                      typeof channelId === 'string'
                        ? channelId
                        : channelId.intID + Math.random()
                    "
                  >
                    {{
                      getChannelForId(userRole.planId, channelId) &&
                      `${getChannelForId(userRole.planId, channelId)?.name}${
                        channelIndex === userRole.channels.length - 1
                          ? ''
                          : ', '
                      }`
                    }}
                  </span>
                </div>
              </div>
            </span>

            <span
              id="actionsContainer"
              v-if="
                !isCurrentUserAccountOwner ||
                (!isUserAccountOwner && isCurrentUserAccountOwner)
              "
            >
              <template
                v-if="
                  (!isUserAccountOwner && isCurrentUserAccountOwner) ||
                  $store.getters.getPermissionLevelForRoleId(userRole.roleId) <=
                    planAdminPermissionLevel
                "
              >
                <i
                  v-if="!userRole.isEditing"
                  class="pi pi-pencil p-mr-3"
                  @click="beginEditingUserRole(userRole)"
                />
                <i
                  v-else
                  class="pi pi-check p-mr-3"
                  @click="editUserRole(userRole)"
                />
              </template>
              <i
                class="pi pi-trash delete-icon"
                @click="deleteUserRole($event, userRole)"
            /></span>
          </div>
        </li>
      </ul>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import User, {UserMostRecentView} from '@/models/User'
import FileUpload from 'primevue/fileupload'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Role from '@/models/Role'
import ID from '@/models/ID'
import UserRole from '@/models/UserRole'
import Plan from '@/models/Plan'
import Channel from '@/models/Channel'
import _ from 'lodash'
import MediaAsset from '@/models/MediaAsset'

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('FileUpload', FileUpload)

export default Vue.extend({
  name: 'UserDetail',
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      newPassword: '',
      fileTypes: '.jpg,.jpeg,.png,.gif,.webp' as string,
      confirmPassword: '',
      editPlan: false as boolean,
      budgetOptions: [
        {
          name: 'Hide',
          code: false,
        },
        {
          name: 'View',
          code: true,
        },
      ],
      image: {} as MediaAsset,
      isImageUpload: false as boolean,
      isLoading: true as boolean,
      planAdminPermissionLevel: Role.LEVEL_PLAN_ADMIN as number,
    }
  },
  mounted() {
    this.image = this.user.profileImage
    this.user.updateOldEmailAddress()
  },
  computed: {
    userRolesFromCurrentPlans(): UserRole[] {
      let returnArray = [] as UserRole[]
      this.$store.getters.allPlans.forEach((plan) => {
        const userRolesInPlans = plan.userRoles.filter(
          (userRole) => {
            if(userRole.userId.intID !== this.user.id.intID){
              return false
            }

            // Only include highest role level for each plan
            const higherRolesForThisUser = plan.userRoles.filter((userRoleCheck) => userRoleCheck.userId.intID === userRole.userId.intID && userRoleCheck.roleId.intID < userRole.roleId.intID)
            if(higherRolesForThisUser.length){
              return false
            }

            return true
          }
        )

        userRolesInPlans.forEach((userRole) => {
          userRole['editingValues']['channels'] = userRole.channels
          userRole['editingValues']['canViewBudget'] = userRole.canViewBudget
          userRole['editingValues']['roleId'] = userRole.roleId
          userRole['name'] = plan.name
          userRole['planId'] = plan.id
          userRole['parentId'] = plan.parentId.intID
            ? plan.parentId.clone()
            : new ID()
          userRole['isEditing'] = false
        })

        returnArray = returnArray.concat(userRolesInPlans)
      })
      return returnArray
    },
    currentUserInArray(): User[] {
      return [this.$store.getters.currentUser]
    },
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    user(): User {
      if (this.$route.params.userId) {
        if (
          this.$store.getters.currentUser &&
          this.$route.params.userId == this.$store.getters.currentUser.id.intID
        ) {
          return this.$store.getters.currentUser
        } else {
          return this.$store.getters.allAccountUsers.find(
            (user) => user.id.intID == Number(this.$route.params.userId)
          )
        }
      }
      return new User()
    },
    usersInPlan(): UserRole[] {
      const plans = this.$store.getters.allPlans.map((item) => {
        return {...item, user: item.users}
      })
      return plans.filter((userRole) => userRole.planId?.intID !== 0)
    },
    roles(): {}[] {
      return this.$store.getters.allRoles
        .filter((role) => role.level >= 250)
        .map((role) => ({
          title: role.title,
          code: role.id,
        }))
    },
    accountOwnerUserRole(): UserRole {
      return this.$store.getters.currentAccount.userRoles.find(
          (accountUserRole) => accountUserRole.roleId.intID === 1
        )
    },
    isCurrentUserAccountOwner(): boolean {
      return this.accountOwnerUserRole && this.accountOwnerUserRole.userId.intID === this.$store.getters.currentUser.id.intID
    },
    isUserAccountOwner(): boolean {
      return this.accountOwnerUserRole && this.accountOwnerUserRole.userId.intID === this.user.id.intID
    },
  },
  methods: {
    getChannelForId(planId: ID, channelId: ID | string): Channel | undefined {
      this.isLoading = false
      const channelApiId =
        typeof channelId === 'string' ? channelId : channelId.apiID
      return this.getChannelsForPlanId(planId).find(
        (channel) => channel.id.apiID === channelApiId
      )
    },
    getChannelsForPlanId(planID: ID): Channel[] {
      const thisPlan = this.$store.getters.allPlans.find(
        (plan) => plan.id.intID === planID.intID
      )
      const isThisPlanLead = this.getIsLeadPlan(thisPlan)
      return thisPlan?.channels.filter(
        (channel) =>
          channel.name !== Channel.CHANNEL_NAME_INITIATIVES &&
          ((isThisPlanLead && !channel.isNested) ||
            (!isThisPlanLead && (channel.isLead || channel.isNested)) ||
            (!channel.isLead && !channel.isNested))
      )
    },
    getIsLeadPlan(plan: Plan) {
      return plan.plans.length ? true : false
    },
    fileUpload() {
      const rowRef = this.$refs.uploadInput as HTMLDivElement
      rowRef.click()
    },
    removeUserFromAccount(event) {
      event.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Remove From Account',
        rejectLabel: 'Cancel',
        message: `Removing this individual from the account will revoke their login credentials and access to all Plans. They can only be added back to the Account and given access to Plans by an Admin. Click to confirm.`,
        target: event.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: async () => {
          // const userRolesForUserInAccount =
          //   this.$store.getters.currentAccount.userRoles.filter(
          //     (userRole) => userRole.userId.intID == this.user.id.intID
          //   )
          // await Promise.all(
          //   userRolesForUserInAccount.map(async (userRole) => {
          //     await this.$store.state.services.users.deleteUserRole(
          //       userRole.id.intID
          //     )
          //   })
          // )

          // TODO: code to test

          
          this.$store.state.services.users.deleteUserFromAccount(this.user.id.intID, this.$store.getters.currentAccount.id.intID).then(
            (response) => {
              this.$store.dispatch('refreshCurrentAccount').then(
                ()=>{
                  this.$store.dispatch('refreshCurrentPlan') 
                }
              )

              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: 'User removed from account.',
                life: 3000,
              })

              this.$router.back()
            },
            (error) => {
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: 'Error',
                detail: 'User could not be removed from account.',
              })
            }
          )
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    deleteUserRole(event, userRole) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        message: `Are you sure you want to remove the team member's access to this plan?`,
        target: event.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.state.services.users
            .deleteUserRole(userRole.id.intID)
            .then(
              () => {
                this.$store.dispatch('refreshCurrentAccount')

                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: 'User removed from plan.',
                  life: 3000,
                })
              },
              (error) =>
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: 'Error removing user from plan. ' + error,
                  life: 3000,
                })
            )
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    navigationTo() {
      this.$router.back()
    },
    cancelUpdate() {
      // TODO: router back?
    },
    updateUser() {
      if (!this.isImageUpload && this.confirmPassword !== this.newPassword) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.error,
          summary: 'confirm password must match',
          life: 3000,
        })
        return
      }else{
        // Update user password before sending request
        this.user.password = this.newPassword
      }

      if (!this.user.mostRecentView) {
        // Ensure most recent view is not null
        this.user.mostRecentView = UserMostRecentView.dashboard
      }

      this.$store.getters.services.users
        .update(this.user)
        .then((user) => {
          if(user.profileImage){
            this.user.profileImage = MediaAsset.fromResponseObject(user.profileImage)
          }
          this.isImageUpload = false
          this.user.updateOldEmailAddress()
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'User updated',
            life: 3000,
          })
        },
        (error)=>{
          console.warn(error)
          if(error.message.includes('status code 422') || error.message.includes('status code 409')){
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'This email address is connected to a user who already has a Seat in this Account.',
            })
          }else{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error updating user.',
              life: 3000,
            })
          }
        })
    },
    onFileUpload(event) {
      const fileData = event.target.files
      this.isImageUpload = true
      const file = new FormData()
      file.append('file', fileData[0])
      this.$store.getters.services.users.mediaAsset(file).then(
        (mediaAsset) => {
          this.image = mediaAsset
          this.user.profileImage = MediaAsset.fromResponseObject(mediaAsset)
          this.updateUser()
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error uploading file. Please try again.',
            life: 3000,
          })
        }
      )
    },
    getAccountUserName() {
      return (
        this.$store.getters.currentUser?.firstName +
        this.$store.getters.currentUser?.lastName
      )
    },
    getRoleForId(roleId: ID) {
      const role = this.$store.getters.allRoles.find(
        (role) => role.id.intID == roleId
      )
      return role ? role.title : '-'
    },
    userCanViewBudget(users) {
      const user = users.filter(
        (user) => user.userId.intID == this.$store.getters.currentUser.id.intID
      )
      return user[0].canViewBudget ? true : false
    },
    beginEditingUserRole(userRole: UserRole) {
      userRole.isEditing = true
    },
    planForUserRole(userRole: UserRole): Plan | undefined {
      return this.$store.getters.currentAccount.plans.find((plan)=>plan.id.intID === userRole.planId.intID)
    },
    channelCountForPlan(plan: Plan): number {
      const isThisPlanLead = plan.plans.length ? true : false
      return plan.channels.filter(
        (channel) => channel.name !== Channel.CHANNEL_NAME_INITIATIVES && ((isThisPlanLead && !channel.isNested) || (!isThisPlanLead && (channel.isLead || channel.isNested)) || (!channel.isLead && !channel.isNested))
      ).length
    },
    editUserRole(userRole: UserRole) {
      const planForUserRole = this.planForUserRole(userRole)
      const rolePayload = new UserRole()

      rolePayload.id = userRole.id
      rolePayload.userId = userRole.userId
      rolePayload.planId = userRole.planId
      rolePayload.roleId = userRole.editingValues.roleId
      rolePayload.accountId = this.$store.getters.currentAccount.id
      rolePayload.canViewBudget = userRole.canViewBudget
      if (userRole.editingValues.channels.length > 0) {
        rolePayload.channels = [...userRole.editingValues.channels]
      }
      if(rolePayload.roleId.intID === 4 || ( rolePayload.roleId.intID === 5 && planForUserRole && userRole.channels.length >= this.channelCountForPlan(planForUserRole))){
        rolePayload.canEditAllChannels = true
      }else{
        rolePayload.canEditAllChannels = false
      }

      this.$store.getters.services.users
        .updateUserRole(rolePayload)
        .then(() => {
          userRole.roleId = userRole.editingValues.roleId
          userRole.channels = [...userRole.editingValues.channels]
          userRole.isEditing = false
        })
        .catch((error) => {
          console.warn(error)
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error editing user details.',
            detal: error,
          })
        })
    },
    onRoleChange(event, userRole) {
      // Default to allow budget view
      userRole.editingValues.canViewBudget = true

      switch (userRole.editingValues.roleId.intID) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          // Account-level (1-3), Plan Admin (4), or Contributor (5) role selected
          userRole.editingValues.channels = this.getChannelsForPlanId(
            userRole.planId
          ).map((channel) => channel.id)
          break
        case 6:
          // Viewer role selected
          userRole.editingValues.channels = []
          break
        default:
          // Do nothing
          break
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.upload-image {
  @include font-mulish;
  background: black;
  padding: 10px 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
  width: 128px;
  label {
    margin-left: 6px;
    cursor: pointer;
  }
  i {
    border: 2px solid #888686;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }
}
.user-detail-container {
  display: flex;
  input {
    width: 300px;
  }
  label {
    font-size: 12px;
  }
}
.profile-photo {
  border-radius: 50%;
  align-items: center;
  text-align: center;
  img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }
}

.account-user-detail {
  width: 45%;
}

.account-user-detail-role {
  width: 65%;
  margin-top: 50px;
}

.plan-setting {
  display: flex;
  justify-content: space-between;
  background: #efefef;
  padding: 0px 10px;
}

.plan-container {
  margin-top: 50px;
}

.plan-setting-detail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  padding: 0px 10px;
}

.team-plan {
  margin-top: 50px;
  padding: 0px !important;
}

.contact-email {
  @include font-mulish-light;
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}

.team-plan {
  position: relative;
  @include font-mulish-light;
  &__edit-overlay {
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: lightgray, $alpha: 0.4);
    z-index: 9;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__head {
    background-color: rgb(239 239 239);
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
  }
  &__item {
    padding: 15px 20px 15px 10px;
    flex-direction: row;
    font-size: 14px;
    border-bottom: 1px solid #b0abab;

    &:last-child {
      border-bottom: none;
    }
    .addUserBtn {
      padding: 10px !important;
    }
    .user-view {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    #avatar {
      position: absolute;
      left: -50px;
      top: 5px;
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .search-field {
      position: relative;
      width: 20%;
      .p-inputtext {
        width: 100%;
      }
      .pi {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }
      .p-listbox {
        width: 100% !important;
        position: absolute;
        z-index: 2;
      }
    }
    // &:last-child {
    //   .p-dropdown {
    //     width: 20%;
    //   }
    // }
  }
  &__subitem {
    padding: 15px 20px 15px 10px;
    flex-direction: row;
    font-size: 14px;
    border-top: 1px solid #e9e8e8;
    border-bottom: 1px solid #b0abab;

    &:last-child {
      border-bottom: 1px solid #b0abab;
    }
    .addUserBtn {
      padding: 10px !important;
    }
    .user-view {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    #avatar {
      position: absolute;
      left: -50px;
      top: 5px;
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .search-field {
      position: relative;
      width: 20%;
      .p-inputtext {
        width: 100%;
      }
      .pi {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }
      .p-listbox {
        width: 100% !important;
        position: absolute;
        z-index: 2;
      }
    }
    // &:last-child {
    //   .p-dropdown {
    //     width: 20%;
    //   }
    // }
  }
  #firstName {
    width: 40%;
  }
  #role,
  #budget,
  #editRights,
  #actionsContainer {
    width: 15%;
    padding-left: 5%;
  }
  #actionsContainer {
    width: 10%;
    text-align: center;
  }
  #toggleDropdown {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 1rem;
    }
  }
  .edit-user,
  .add-user {
    border-color: transparent;
    // padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: row;
    label {
      margin-bottom: 8px;
      font-size: 15px;
      @include font-mulish-light;
    }
    .p-button-text {
      @include font-mulish-light;
      padding-left: 4px;
      padding-right: 5px;
      padding-bottom: 3px;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      .pi-plus-circle {
        margin-right: 3px;
      }
    }
    &__field {
      padding-right: 15px;
      display: flex;
      flex-direction: column;
    }
    #addUser,
    #editUser {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 5px;
      gap: 5px;
      cursor: pointer;
      i {
        font-size: 12px;
      }
      span {
        width: max-content;
      }
    }
  }
}

.team-subPlan {
  position: relative;
  @include font-mulish-light;
  &__edit-overlay {
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: lightgray, $alpha: 0.4);
    z-index: 9;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__head {
    background-color: rgb(239 239 239);
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
  }

  &__subitem {
    padding: 15px 20px 15px 10px;
    flex-direction: row;
    font-size: 14px;
    border-top: 1px solid #b0abab;
    margin-top: 5px;

    .addUserBtn {
      padding: 10px !important;
    }
    .user-view {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    #avatar {
      position: absolute;
      left: -50px;
      top: 5px;
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .search-field {
      position: relative;
      width: 20%;
      .p-inputtext {
        width: 100%;
      }
      .pi {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }
      .p-listbox {
        width: 100% !important;
        position: absolute;
        z-index: 2;
      }
    }
    // &:last-child {
    //   .p-dropdown {
    //     width: 20%;
    //   }
    // }
  }
  #firstName {
    width: 30%;
  }
  #lastName {
    width: 20%;
  }

  #role {
    width: 20%;
    .edit {
      padding-left: 1px;
    }
  }
  #budget {
    width: 20%;
    .edit {
      padding-left: 1px;
    }
  }
  #editRights {
    width: 20%;
    .edit {
      padding-left: 6px;
    }
  }
  #toggleDropdown {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 1rem;
    }
  }
  .edit-user,
  .add-user {
    border-color: transparent;
    // padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: row;
    label {
      margin-bottom: 8px;
      font-size: 15px;
      @include font-mulish-light;
    }
    .p-button-text {
      @include font-mulish-light;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 3px;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      .pi-plus-circle {
        margin-right: 3px;
      }
    }
    &__field {
      padding-right: 15px;
      display: flex;
      flex-direction: column;
    }
    #addUser,
    #editUser {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 5px;
      gap: 5px;
      cursor: pointer;
      i {
        font-size: 12px;
      }
      span {
        width: max-content;
      }
    }
  }
}
.mutliselect {
  width: 200px;
}
</style>
