<template>
  <div class="view-wrapper" :class="{'is-initiative-detail': isInitiative}">
    <ViewHeader
      :title="viewTitle"
      :titleLabel="isInitiative ? 'Initiative' : 'Tactic'"
      :hideSectionHomeButton="true"
      :isFullViewport="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
          @click="handleDeleteTactic($event)"
          class="p-button-text p-mr-auto"
          icon="pi pi-trash delete-icon"
          v-if="!isCreateTactic && canUserDeleteTactic"
        />
        <Button
          @click="showCancelConfirmation($event)"
          class="p-mr-2 p-button-outlined"
          :label="saveButtonDisabled ? 'Close' : 'Cancel'"
        />
        <SplitButton 
          id="btn_tactic_save"
          :class="saveButtonDisabled ? 'p-disabled' : null"
          :disabled="saveButtonDisabled"
          @click="handleSaveTactic"
          label="Save"
          :model="saveMenuItems" 
        ></SplitButton>
      </template>
    </ViewHeader>
    <ViewMain :isFullViewport="true">
      <div class="tactic-detail-wrapper">
        <div class="details-container">
          <div class="p-d-flex">
            <div class="p-field p-mr-2">
              <label v-if="isInitiative" for="name">Name</label>
              <label v-else for="name">Topic</label>
              <InputText
                id="name"
                v-model="localTactic.title"
                :placeholder="
                  isInitiative ? 'New Initiative' : 'New Tactic'
                "
                @input="nonConditionalChange"
                autocomplete="off"
                :class="{'p-invalid': invalidFields.includes('title')}"
                :disabled="!canUserEditFields"
              />
            </div>
            <template v-if="!isInitiative">
              <div class="p-field p-mr-2">
                <label for="channel">Channel</label>
                <Dropdown
                  inputId="channel"
                  v-model="selectedChannel"
                  :options="channelOptions"
                  optionLabel="name"
                  optionDisabled="isDisabled"
                  placeholder="Select channel"
                  data-key="key"
                  @change="
                    () => {
                      onChannelInputChange()
                      nonConditionalChange()
                    }
                  "
                  :class="{
                    'p-invalid': invalidFields.includes('channel'),
                  }"
                  :disabled="!canUserEditChannel"
                />
              </div>
            </template>
            <div class="p-field p-mr-2">
              <label v-if="isInitiative" for="type">Category</label>
              <label v-else for="type">Type</label>
              <Dropdown
                inputId="type"
                v-model="selectedType"
                :options="typeOptions"
                optionLabel="name"
                placeholder="Select Type"
                data-key="key"
                @change="
                  () => {
                    onTypeInputChange()
                    nonConditionalChange()
                  }
                "
                :class="{'p-invalid': invalidFields.includes('type')}"
                :disabled="!canUserEditFields || (selectedChannel && !selectedChannel.name) || (isInitiative && localTactic.id?.intID !== 0)"
              />
            </div>
            <template
              v-if="
                !isInitiative && selectedType.id && platformOptions.length
              "
            >
              <div class="p-field p-mr-2">
                <label for="platform">Platform</label>
                <MultiSelect
                  v-if="selectedType.shouldTacticsAllowMultiplePlatforms"
                  inputId="platform"
                  v-model="selectedPlatforms"
                  :options="platformOptions"
                  optionLabel="name"
                  placeholder="No Platform"
                  data-key="key"
                  :showClear="selectedPlatforms.length ? true : false"
                  @change="
                    () => {
                      onPlatformInputChange()
                      nonConditionalChange()
                    }
                  "
                  :disabled="!canUserEditFields"
                />
                <Dropdown
                  v-else
                  inputId="platform"
                  v-model="selectedPlatformSingle"
                  :options="platformOptions"
                  optionLabel="name"
                  placeholder="No Platform"
                  data-key="key"
                  :showClear="selectedPlatforms.length ? true : false"
                  @change="
                    () => {
                      onPlatformInputChange()
                      nonConditionalChange()
                    }
                  "
                  :disabled="!canUserEditFields"
                />
              </div>
            </template>
          </div>

          <div
            class="p-mb-4" 
            v-if="
              !isInitiative &&
              !isMonthlyOnly &&
              selectedChannel.enableTacticFlagging
            "
          >
            <Checkbox
              id="isFlagged"
              v-model="localTactic.isFlagged"
              :binary="true"
              class="p-mr-2"
              @change="
                () => {
                  onInputChange()
                  nonConditionalChange()
                }
              "
              :disabled="!canUserEditFields"
            />
            <label for="isFlagged">Flagged Tactic: Show On Flow View</label>
          </div>
        </div>

        <div class="time-frame-container">
          <div class="p-d-flex p-ai-center" v-show="isMonthlyOnly">
            <div v-if="isMonthlyOnly" class="p-field p-mr-2">
              <label for="start">Month</label>
              <Calendar
                id="start"
                v-model="localTactic.startDate"
                view="month"
                dateFormat="mm/yy"
                :yearNavigator="true"
                :manualInput="false"
                :yearRange="$store.getters.datePickerYearRange"
                :showTime="false"
                appendTo="body"
                @hide="
                  () => {
                    onMonthlyFocusDateSelect()
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields"
              />
            </div>
          </div>
          <div class="p-d-flex p-ai-center" v-if="!isMonthlyOnly">
            <div class="p-field p-mr-2" v-if="!localTactic.isUnscheduled">
              <label for="start">Start Date</label>
              <Calendar
                id="start"
                v-model="localTactic.startDate"
                :manualInput="false"
                :monthNavigator="true"
                :yearNavigator="true"
                :yearRange="$store.getters.datePickerYearRange"
                :showTime="shouldShowTimeInput"
                hourFormat="12"
                dateFormat="m/d/yy"
                appendTo="body"
                @hide="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                    clearRepeat()
                  }
                "
                :disabled="!canUserEditFields"
              >
                <template #footer v-if="shouldShowTimeInput">
                  <TimePicker
                    :date="currentTactic.startDate"
                    :setDate="updateCurrentTacticStartDate"
                  />
                </template>
              </Calendar>
            </div>
            <div v-if="shouldShowEndDateInput && !localTactic.isUnscheduled" class="p-field p-mr-2">
              <label for="end">End Date</label>
              <Calendar
                id="end"
                v-model="localTactic.endDate"
                :manualInput="false"
                :monthNavigator="true"
                :yearNavigator="true"
                :yearRange="$store.getters.datePickerYearRange"
                :showTime="shouldShowTimeInput"
                hourFormat="12"
                dateFormat="m/d/yy"
                appendTo="body"
                @hide="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields"
              >
                <template #footer v-if="shouldShowTimeInput">
                  <TimePicker
                    :date="currentTactic.endDate"
                    :setDate="updateCurrentTacticEndDate"
                  />
                </template>
              </Calendar>
            </div>
            <div class="p-ml-4 p-mr-4" v-if="!localTactic.isUnscheduled">
              <Checkbox
                id="hasEndDate"
                class="p-mr-2"
                v-model="shouldShowEndDateInput"
                :binary="true"
                @change="
                  () => {
                    onMultiDayChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields"
              />
              <label for="hasEndDate">Show Duration</label>
            </div>
            <div class="p-ml-4 p-mr-4" v-if="!localTactic.isUnscheduled">
              <Checkbox
                id="isAllDay"
                class="p-mr-2"
                v-model="localTactic.isAllDay"
                :binary="true"
                @change="
                  () => {
                    onAllDayChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields"
              />
              <label for="isAllDay">All-Day</label>
            </div>
            <div v-if="!isInitiative">
              <Checkbox
                id="isUnscheduled"
                class="p-mr-2"
                v-model="localTactic.isUnscheduled"
                :binary="true"
                @change="
                  () => {
                    onUnscheduledChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields"
              />
              <label for="isUnscheduled">Parking Lot</label>
              <span
                v-tooltip.bottom="'Select &quot;Parking Lot&quot; when you want to enter unscheduled tactic ideas for the future. Once saved, they can be accessed via the Table view, Parking Lot. Turn off the &quot;Parking Lot&quot; designation when you are ready to schedule.'"
                class="pi pi-question-circle tooltip-color p-ml-2"
              ></span>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-ai-center" v-if="!isInitiative && !localTactic.isUnscheduled">
          <div class="p-mr-4" v-if="(selectedType && !selectedType.name) || (selectedType && selectedType.name && selectedType.name.toLowerCase() !== $store.getters.monthlyFocusTypeName)">
            <Checkbox
              id="tacticRepeats"
              class="p-mr-2"
              v-model="localTactic.repeats"
              :binary="true"
              @change="
                () => {
                  onInputChange()
                  nonConditionalChange()
                }
              "
              :disabled="!canUserEditFields"
            />
          
            <label for="tacticRepeats" >Repeats</label> 
          </div>
          <div
            v-if="localTactic.repeats && !localTactic.isCustomRecurrence"
            class="p-field p-mr-2"
          >
            <label for="repeat-on">When:</label>
            <Dropdown
              id="repeat-on"
              :options="repeatOptions"
              :class="{'p-invalid': invalidFields.includes('rrule')}"
              v-model="localTactic.rrule"
              optionLabel="label"
              optionValue="value"
              placeholder="Repeats on..."
              :showClear="localTactic.rrule != ''"
              @change="
                (e) => {
                  onRepeatRuleInputChange(e)
                  onInputChange()
                  nonConditionalChange()
                }
              "
            />
          </div>
          <div
            v-if="localTactic.repeats && localTactic.isCustomRecurrence"
            class="p-mr-3"
            :class="{
              'p-invalid': invalidFields.includes('customRepeat'),
            }"
          >
            <strong>Custom:</strong>
            {{ customRecurrence.recurrenceText }}
            <a
              href=""
              class="p-ml-2 underline"
              @click="handleEditCustomRuleClick"
              >Edit</a
            >
            |
            <a
              href=""
              class="p-ml-2 underline"
              @click="handleRemoveCustomRuleClick"
              >Remove</a
            >
          </div>
          <div v-if="localTactic.repeats" class="p-field p-mr-2">
            <label for="repeatStop">Stop repeating on:</label>
            <Calendar
              id="repeatStop"
              v-model="localTactic.recurrenceEndDate"
              :manualInput="false"
              :monthNavigator="true"
              :yearNavigator="true"
              :yearRange="$store.getters.datePickerYearRange"
              :showTime="false"
              hourFormat="12"
              dateFormat="m/d/yy"
              placeholder="Never"
              @hide="
                () => {
                  onInputChange()
                  nonConditionalChange()
                }
              "
            />
          </div>
        </div>

        <div class="summary-container p-mt-6" v-if="currentPlan.isTacticSummaryFieldEnabled">
          <label for="summary">Notes</label>
          <QuillEditor 
            id="summary"
            class="p-mt-2"
            v-model="localTactic.summary"
            @text-change="(event) => {
                nonConditionalChange()
                onEditorTextChange(event, 'notesMessage')
              }"
            :readonly="!canUserEditFields"
            :class="{'disable-editor': !canUserEditFields}"
            ></QuillEditor>
            <div id="notesMessage"></div>
        </div>

        <div
            v-if="
              planLevelTacticFields &&
              planLevelTacticFields.length && 
              !isInitiative
            "
            class="
              custom-properties-container
              p-d-flex p-flex-wrap p-ai-start p-mt-4
            "
          >
          <template v-for="field of planLevelTacticFields">
            <div :key="field.key" class="p-field p-mr-2" :style="{
                'flex-basis': isFieldType(field, 'richtext') ? '100%' : 'auto'
              }">
                <label :for="'custom_field_' + field.id.intID">{{
                  field.name
                }}</label>
                <div v-if="isFieldType(field, 'image')">
                  <div
                    v-if="getFieldValueForFieldId(field.id).value"
                    class="custom-field-image-controls"
                  >
                    <div class="custom-field-image-controls-container">
                      <Button
                        label=""
                        class="p-button-lg p-button-outlined"
                        icon="pi pi-times-circle"
                        @click="removeCustomFieldImage(field.id)"
                      />
                      <Button
                        label=""
                        class="p-button-lg p-button-outlined"
                        icon="pi pi-search-plus"
                        @click="
                          () => {
                            showCustomFieldImageDialog = true
                            customFieldDialogImage = getCustomImageFieldUrl(
                              field.id
                            )
                          }
                        "
                      />
                    </div>
                    <img
                      class="custom-field-image"
                      :src="getCustomImageFieldUrl(field.id)"
                      alt=""
                    />
                  </div>
                  <FileUpload
                    :name="'customFieldImage' + field.id.intID"
                    :customUpload="true"
                    :maxFileSize="10000000"
                    :fileLimit="1"
                    mode="basic"
                    chooseLabel="Upload Image"
                    :auto="true"
                    accept="image/*"
                    @uploader="
                      (fileData) => uploadCustomFieldImage(field.id, fileData)
                    "
                    v-else
                  >
                    <template #empty>
                      <p>Drag and drop an image here to upload.</p>
                    </template>
                  </FileUpload>
                </div>
                <div v-else-if="isFieldType(field, 'richtext')">
                  <QuillEditor 
                    :id="'custom_field_' + field.id.intID"
                    v-model="getFieldValueForFieldId(field.id).value"
                    @text-change="(val) => {
                      conditionalChange(field, val)
                      onEditorTextChange(val, 'custom_field_' + field.id.intID + '_message')
                    }"
                    :readonly="!canUserEditFields"
                    :class="{'disable-editor': !canUserEditFields}"
                    ></QuillEditor>
                    <div :id="'custom_field_' + field.id.intID + '_message'"></div>
                </div>
                <div v-else>
                  <div
                    class="p-d-flex p-ai-center"
                    v-if="isFieldType(field, 'url')"
                  >
                    <InputText
                      :inputId="'custom_field_' + field.id.intID"
                      v-model="getFieldValueForFieldId(field.id).value"
                      @input="(val) => conditionalChange(field, val)"
                      autocomplete="off"
                      :disabled="!canUserEditFields"
                      v-if="
                        editModeFieldIds.includes(field.id.intID) ||
                        getFieldValueForFieldId(field.id).value == ''
                      "
                    />
                    <a
                      class="edit-link"
                      v-else
                      :href="
                        editModeFieldIds.includes(field.id.intID) &&
                        getFieldValueForFieldId(field.id).value.indexOf(
                          '://'
                        ) > -1
                          ? getFieldValueForFieldId(field.id).value
                          : `${getFieldValueForFieldId(field.id).value}`
                      "
                      target="_blank"
                      >{{ getFieldValueForFieldId(field.id).value }}</a
                    >
                    <i
                      class="pi pi-check p-ml-2"
                      style="font-size: 1.5rem; font-weight: bold;"
                      @click="updateEditModeFieldIds(field.id.intID)"
                      :disabled="!getFieldValueForFieldId(field.id).value"
                      v-if="
                        getFieldValueForFieldId(field.id).value == '' ||
                        editModeFieldIds.includes(field.id.intID)
                      "
                    />

                    <i
                      class="pi pi-pencil p-ml-2"
                      style="font-size: 1.5rem"
                      v-else
                      @click="updateEditModeFieldIds(field.id.intID)"
                    ></i>
                  </div>
                  <div class="p-d-flex p-ai-center" v-else>
                    <InputText
                      :inputId="'custom_field_' + field.id.intID"
                      v-model="getFieldValueForFieldId(field.id).value"
                      @input="(val) => conditionalChange(field, val)"
                      autocomplete="off"
                      :disabled="!canUserEditFields"
                    />
                  </div>
                </div>
              </div>
          </template>
        </div>

        <div
            v-if="
              typeLevelTacticFields &&
              typeLevelTacticFields.length
            "
            class="
              custom-properties-container
              p-d-flex p-flex-wrap p-ai-start p-mt-4
            "
          >
          <template v-for="field of typeLevelTacticFields">
            <div :key="field.key" class="p-field p-mr-2" :style="{
                'flex-basis': isFieldType(field, 'richtext') ? '100%' : 'auto'
              }">
                <label :for="'custom_field_' + field.id.intID">{{
                  field.name
                }}</label>
                <div v-if="isFieldType(field, 'image')">
                  <div
                    v-if="getFieldValueForFieldId(field.id).value"
                    class="custom-field-image-controls"
                  >
                    <div class="custom-field-image-controls-container">
                      <Button
                        label=""
                        class="p-button-lg p-button-outlined"
                        icon="pi pi-times-circle"
                        @click="removeCustomFieldImage(field.id)"
                      />
                      <Button
                        label=""
                        class="p-button-lg p-button-outlined"
                        icon="pi pi-search-plus"
                        @click="
                          () => {
                            showCustomFieldImageDialog = true
                            customFieldDialogImage = getCustomImageFieldUrl(
                              field.id
                            )
                          }
                        "
                      />
                    </div>
                    <img
                      class="custom-field-image"
                      :src="getCustomImageFieldUrl(field.id)"
                      alt=""
                    />
                  </div>
                  <FileUpload
                    :name="'customFieldImage' + field.id.intID"
                    :customUpload="true"
                    :maxFileSize="10000000"
                    :fileLimit="1"
                    mode="basic"
                    chooseLabel="Upload Image"
                    :auto="true"
                    accept="image/*"
                    @uploader="
                      (fileData) => uploadCustomFieldImage(field.id, fileData)
                    "
                    v-else
                  >
                    <template #empty>
                      <p>Drag and drop an image here to upload.</p>
                    </template>
                  </FileUpload>
                </div>
                <div v-else-if="isFieldType(field, 'richtext')">
                  <QuillEditor 
                    :id="'custom_field_' + field.id.intID"
                    v-model="getFieldValueForFieldId(field.id).value"
                    @text-change="(val) => {
                      conditionalChange(field, val)
                      onEditorTextChange(val, 'custom_field_' + field.id.intID + '_message')
                    }"
                    :readonly="!canUserEditFields"
                    :class="{'disable-editor': !canUserEditFields}"
                    ></QuillEditor>
                    <div :id="'custom_field_' + field.id.intID + '_message'"></div>
                </div>
                <div v-else>
                  <div
                    class="p-d-flex p-ai-center"
                    v-if="isFieldType(field, 'url')"
                  >
                    <InputText
                      :inputId="'custom_field_' + field.id.intID"
                      v-model="getFieldValueForFieldId(field.id).value"
                      @input="(val) => conditionalChange(field, val)"
                      autocomplete="off"
                      :disabled="!canUserEditFields"
                      v-if="
                        editModeFieldIds.includes(field.id.intID) ||
                        getFieldValueForFieldId(field.id).value == ''
                      "
                    />
                    <a
                      class="edit-link"
                      v-else
                      :href="
                        editModeFieldIds.includes(field.id.intID) &&
                        getFieldValueForFieldId(field.id).value.indexOf(
                          '://'
                        ) > -1
                          ? getFieldValueForFieldId(field.id).value
                          : `${getFieldValueForFieldId(field.id).value}`
                      "
                      target="_blank"
                      >{{ getFieldValueForFieldId(field.id).value }}</a
                    >
                    <i
                      class="pi pi-check p-ml-2"
                      style="font-size: 1.5rem; font-weight: bold;"
                      @click="updateEditModeFieldIds(field.id.intID)"
                      :disabled="!getFieldValueForFieldId(field.id).value"
                      v-if="
                        getFieldValueForFieldId(field.id).value == '' ||
                        editModeFieldIds.includes(field.id.intID)
                      "
                    />

                    <i
                      class="pi pi-pencil p-ml-2"
                      style="font-size: 1.5rem"
                      v-else
                      @click="updateEditModeFieldIds(field.id.intID)"
                    ></i>
                  </div>
                  <div class="p-d-flex p-ai-center" v-else>
                    <InputText
                      :inputId="'custom_field_' + field.id.intID"
                      v-model="getFieldValueForFieldId(field.id).value"
                      @input="(val) => conditionalChange(field, val)"
                      autocomplete="off"
                      :disabled="!canUserEditFields"
                    />
                  </div>
                </div>
              </div>
          </template>
        </div>
      </div>

      <div>
        <div class="plan-elements-container" v-if="$store.getters.currentTags.length">
          <hr class="p-my-4" />
          <div class="p-d-flex p-jc-between p-ai-start">
            <h2>Tags</h2>
            <Button
              label="Tags"
              icon="pi pi-plus-circle"
              @click="shouldShowTagList = true"
              :disabled="!canUserEditFields || !canUserEditTags"
            />
          </div>
          <div
            class="p-d-flex p-jc-start p-ai-start"
            style="width: 80%; min-width: 60rem"
          >
            <template v-for="(tagCategory, tagCategoryIndex) in currentCategorizedTagsAssignedToTactic">
              <div class="tag-list-column p-mr-6" :key="tagCategoryIndex" v-show="tagCategory.tags.length">
                <h3>{{tagCategory.name}}</h3>
                <div class="p-d-flex p-flex-column p-ai-start">
                  <template v-for="tag of tagCategory.tags">
                    <div
                      :key="tag.id.intID"
                      class="p-d-flex p-ai-center p-mr-2 p-mb-2 tag-chip"
                    >
                      <div
                        class="p-mr-2"
                        :class="{
                          'is-nested': tag.isNested,
                          'is-lead': tag.isLead,
                        }"
                      >
                        <template v-if="isLeadPlan && tag.isNested">
                          {{ tag.abbreviatedPlanName }} > {{ tag.title }}
                        </template>
                        <template v-else>
                          {{ tag.title }}
                        </template>
                      </div>
                      <Button
                        icon="pi pi-times-circle"
                        class="p-button-text"
                        @click="handleRemoveTag(tag.id)"
                        :disabled="!canUserEditTags"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

        <template v-if=" !isInitiative && filteredInitiativesInCategories.length && !localTactic.isUnscheduled">
          <div class="related-initiatives-container">
            <hr class="p-my-4" />
            <h2>Initiatives</h2>
            <div class="p-d-flex">
                <template v-for="(type) in filteredInitiativesInCategories">
                  <div class="p-field p-mr-2 initiative-item-container" v-if="type.tactics.length" :key="type.key">
                    <label :for="`initiatives_${type.name}`">{{ type.name }}</label>
                    <Dropdown
                      :id="`initiatives_${type.name}`"
                      v-model="getInitiativeMappingForType(type).initiativeId"
                      :options="type.tactics"
                      optionLabel="title"
                      optionValue="id"
                      dataKey="key"
                      :placeholder="`Select ${type.name}`"
                      :showClear="getInitiativeMappingForType(type).initiativeId && getInitiativeMappingForType(type).initiativeId.intID != 0"
                      @change="
                        ($event) => {
                          onRelatedInitiativeChange($event, type)
                          onInputChange()
                          nonConditionalChange()
                        }
                      "
                      :disabled="!canUserEditFields"
                    >
                      <template #value="slotProps">
                        <div
                          class="initiative-item"
                          v-if="getInitiativeMappingForType(type).initiativeId && getInitiativeMappingForType(type).initiativeId.intID != 0"
                          :class="{
                            'is-nested': getInitiativeForId(slotProps.value)
                              .isNested,
                            'is-lead': getInitiativeForId(slotProps.value).isLead,
                          }"
                        >
                          <template
                            v-if="
                              isLeadPlan &&
                              getInitiativeForId(slotProps.value).isNested
                            "
                          >
                            {{
                              getInitiativeForId(slotProps.value)
                                .abbreviatedPlanName
                            }}
                            > {{ getInitiativeForId(slotProps.value).title }}
                          </template>
                          <template v-else>
                            {{ getInitiativeForId(slotProps.value).title }}
                          </template>
                        </div>
                        <div v-else>
                          {{ slotProps.placeholder }}
                        </div>
                      </template>
                      <template #option="slotProps">
                        <div
                          class="initiative-item"
                          :class="{
                            'is-nested': slotProps.option.isNested,
                            'is-lead': slotProps.option.isLead,
                          }"
                        >
                          <div v-if="isLeadPlan && slotProps.option.isNested">
                            {{ slotProps.option.abbreviatedPlanName }} >
                            {{ slotProps.option.title }}
                          </div>
                          <div v-else>
                            {{ slotProps.option.title }}
                          </div>
                        </div>
                      </template>
                    </Dropdown>  
                  </div>
                </template>
            </div>
          </div>
        </template>

        <div
          class="budget-fields-container"
          v-if="
            !isInitiative && !isMonthlyOnly && currentPlan.isBudgetEnabled && $store.getters.currentUserRole.canViewBudget
          "
        >
          <hr class="p-my-4" />
          <h2>Budget</h2>
          <div class="p-d-flex">
            <div class="p-field p-mr-2">
              <label for="estimatedCost">Estimated Cost</label>
              <InputCurrency
                id="estimatedCost"
                :value.sync="localTactic.estimatedCost"
                @focus="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                @blur="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields || !canUserEditBudget"
              />
            </div>

            <div class="p-field p-mr-2">
              <label for="actualCost">Actual Cost</label>
              <InputCurrency
                id="actualCost"
                :value.sync="localTactic.actualCost"
                @focus="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                @blur="
                  () => {
                    onInputChange()
                    nonConditionalChange()
                  }
                "
                :disabled="!canUserEditFields || !canUserEditBudget"
              />
            </div>
          </div>
        </div>

        <!-- Media section hidden for MVP -->
        <!-- <div class="media-fields-container" v-if="!isInitiative">
        <hr class="p-my-4" />
        <h2>MEDIA</h2>
        <div class="p-d-flex">
          <div class="p-field p-mr-2">
            <label for="impressions">Impressions</label>
            <InputNumber
              id="impressions"
              v-model="localTactic.impressions"
              @input="onInputChange"
              autocomplete="off"
            />
          </div>

          <div class="p-field p-mr-2">
            <label for="ratingPoints">Rating Points</label>
            <InputNumber
              id="ratingPoints"
              v-model="localTactic.ratingPoints"
              @input="onInputChange"
              autocomplete="off"
            />
          </div>
        </div>
      </div> -->
      </div>

      <div class="tactic-detail-documents-container" v-if="isInitiative">
        <hr class="p-my-4" />
        <div class="p-d-flex p-jc-between p-ai-start">
          <h2>Documents</h2>
        </div>
        <DataTable :value="localTactic.documents" :autoLayout="true" v-if="localTactic.documents.length">
          <Column header="Name" key="id.intID">
            <template #body="slotProps">
              {{ slotProps.data.asset.name }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="p-d-flex p-jc-end p-ai-center">
                <Button
                  class="p-button-text p-button-icon-only"
                >
                  <a 
                    target="_blank" 
                    :href="slotProps.data.asset.fileLocation" 
                    download
                    style="color: #000;"
                    >
                    <i class="pi pi-download"></i>
                  </a>
                </Button>
                <Button
                  icon="pi pi-trash"
                  class="p-button-text"
                  @click="(e) => deleteDocument(e, slotProps.data.id.intID, localTactic)"
                ></Button>
              </div>
            </template>
          </Column>
        </DataTable>

        <div class="upload-document-container p-d-flex">
          <label 
            for="document-upload" 
            class="p-button p-component p-button-text" 
            style="border: 0.1rem solid #000; border-radius: 0;"
            >
              Select File
              <input
                id="document-upload"
                type="file"
                @change="(e) => onFileUpload(e, localTactic)"
                hidden
              />
          </label>
          <InputText
            v-model="localTactic.newFileName"
          />
          <Button
            label="Upload"
            icon="pi pi-upload"
            class="p-button-text"
            @click="uploadFile(localTactic)"
          ></Button>
        </div>
      </div>

      <div class="tactic-detail-supporting-tactics-container" v-if="isInitiative">
        <hr class="p-my-4" />
        <div class="p-d-flex p-jc-between p-ai-start">
          <h2>Supporting Tactics</h2>
        </div>
        <DataTable :value="supportingTactics" :autoLayout="true" v-if="supportingTactics.length">
          <Column field="title" header="Topic" sortable>
            <template #body="slotProps">
              <span :class="{'is-lead': slotProps.data.isLead }">
                {{ getTitleForTactic(slotProps.data) }}
              </span>
            </template>
          </Column>
          <Column field="channelName" header="Channel" sortable>
            <template #body="slotProps">
              {{ slotProps.data.channelName }}
            </template>
          </Column>
          <Column field="tacticTypeName" header="Tactic Type" sortable>
            <template #body="slotProps">
              {{ slotProps.data.tacticTypeName }}
            </template>
          </Column>
          <Column field="tacticPlatformIds" header="Platform">
            <template #body="slotProps">
              {{ getPlatformNamesForTactic(slotProps.data) }}
            </template>
          </Column>
          <Column field="startDate" header="Start Date" sortable>
            <template #body="slotProps">
              {{ slotProps.data.startDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column field="endDate" header="End Date" sortable>
            <template #body="slotProps">
              {{ slotProps.data.endDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column header="Notes" key="summary">
            <template #body="slotProps">
              <Button
                  v-if="slotProps.data.summary !== null && slotProps.data.summary !== ''"
                  icon="pi pi-eye"
                  class="p-button-icon-only p-button-rounded p-button-text"
                  @mouseenter="(event)=> {
                    currentOverlayHTML = slotProps.data.summary ? slotProps.data.summary : ''
                    $refs.richTextFieldOverlay.show(event)
                  }"
                  @mouseleave="(event)=> {
                    $refs.richTextFieldOverlay.hide()
                  }"
                ></Button>
                <template v-else>
                  None
                </template>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div
                class="p-d-flex p-jc-end"
                v-if="
                  (isLeadPlan && slotProps.data.isLead) ||
                  (!isLeadPlan && !slotProps.data.isLead)
                "
              >
                <Button
                  label="Open"
                  icon="pi pi-arrow-circle-up"
                  class="p-button-text"
                  @click="()=>{
                    $router.push({
                      path: `/account/${$store.getters.currentAccount.id.intID}/plan/${$route.params.planId}/tactic/${slotProps.data.id.intID}`,
                    })
                  }"
                ></Button>
              </div>
            </template>
          </Column>
        </DataTable>
        <div v-else>
          <p>No supporting tactics found.</p>
        </div>
      </div>

      <Dialog
        header="Tags"
        :visible.sync="shouldShowTagList"
        :modal="true"
      >
        <TagList
          :selectedTags="localTactic.tags"
          :allowSelect="true"
          :allowEdit="false"
          :allowCreate="false"
          :allowList="true"
          @updateSelection="onTagListUpdate"
        />
      </Dialog>
      <Dialog
        :dismissableMask="true"
        header=""
        :visible.sync="showCustomFieldImageDialog"
        :modal="true"
        @hide="() => (customFieldDialogImage = '')"
      >
        <div class="custom-image-dialog">
          <Button
            label="Download"
            class="p-button-lg p-button-outlined"
            icon="pi pi-download"
            @click="openInNewWindow(customFieldDialogImage)"
          />
          <img :src="customFieldDialogImage" alt="" />
        </div>
      </Dialog>
      <Dialog
        :dismissableMask="true"
        header="Custom Recurrence"
        :visible.sync="showCustomRecurrenceDialog"
        :modal="true"
        @hide="handleCustomRecurrenceModalClose"
      >
        <div class="custom-recurrence-dialog-body">
          <div class="p-d-flex p-ai-center p-mt-5 p-mb-5">
            <div class="p-field p-mr-2">Repeat every</div>
            <div class="p-field p-mr-2">
              <InputNumber
                v-model="customRecurrence.interval"
                class="custom-recurrence-number-input"
              />
            </div>
            <div class="p-field p-mr-2">
              <Dropdown
                id="custom-recurrence-freq"
                v-model="customRecurrence.freq"
                :options="customRecurrenceFields.freq"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </div>
          <div v-if="customRecurrence.freq === 'WEEKLY'">
            <h3>Repeat on</h3>
            <div
              v-for="day in customRecurrenceFields.byweekday"
              :key="day.value"
              class="p-field-radiobutton"
            >
              <RadioButton
                :id="day.value"
                name="customRecurrence.byweekday"
                :value="day.value"
                v-model="customRecurrence.byweekday"
              />
              <label :for="day.value">{{ day.label }}</label>
            </div>
          </div>
          <div v-if="customRecurrence.freq === 'MONTHLY'" class="p-field">
            <label for="custom-monthly-value">On:</label>
            <Dropdown
              id="custom-monthly-value"
              :options="customRecurrenceMonthlyOptions"
              v-model="customRecurrence.monthlyRule"
              optionLabel="label"
              optionValue="value"
            />
          </div>
          <div class="p-mt-5 p-mb-auto">
            <Button
              @click="
                () => {
                  onCustomRecurrenceSubmit()
                  nonConditionalChange()
                }
              "
              label="Save Custom Rule"
              :disabled="!isCustomRecurrenceValid"
            />
          </div>
        </div>
      </Dialog>

      <OverlayPanel ref="richTextFieldOverlay" class="rich-text-field-overlay" appendTo="body">
        <div class="notes-field-content-container" v-html="currentOverlayHTML"></div>
      </OverlayPanel>

    </ViewMain>
  </div>
</template>

<script lang="ts">
import {format, isSameDay} from 'date-fns'
import axios from 'axios'
import Vue from 'vue'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Dialog from 'primevue/dialog'
import RadioButton from 'primevue/radiobutton'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import TagList from '@/views/TagList.vue'
import Tactic from '@/models/Tactic'
import Plan from '@/models/Plan'
import Channel from '@/models/Channel'
import CustomRecurrence, {
  RecurrenceFrequency,
  RecurrenceByweekday,
} from '@/models/CustomRecurrence'
import TacticPlatform from '@/models/TacticPlatform'
import TacticType from '@/models/TacticType'
import TacticField, {TacticFieldType} from '@/models/TacticField'
import TacticFieldValue from '@/models/TacticFieldValue'
import ID from '@/models/ID'
import Tag, {TagType} from '@/models/Tag'
import AlertMessage, {
  AlertMessageSeverity,
} from '@/models/AlertMessage'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputCurrency from '@/components/InputCurrency.vue'
import TimePicker from '@/components/TimePicker.vue'
import {RRule, rrulestr} from 'rrule'
import FileUpload from 'primevue/fileupload'
import Role from '@/models/Role'
import TagCategory from '@/models/TagCategory'
import PlanDocument from '@/models/PlanDocument'
import RelatedInitiativeMapping from '@/models/RelatedInitiativeMapping'
import { constrainPoint } from '@fullcalendar/vue'
import Tooltip from 'primevue/tooltip'
import QuillEditor from '@/components/QuillEditor.vue'

Vue.component('Checkbox', Checkbox)
Vue.component('Dropdown', Dropdown)
Vue.component('MultiSelect', MultiSelect)
Vue.component('Dialog', Dialog)
Vue.component('InputNumber', InputNumber)
Vue.component('Button', Button)
Vue.component('SplitButton', SplitButton)
Vue.component('FileUpload', FileUpload)
Vue.component('TimePicker', TimePicker)
Vue.component('RadioButton', RadioButton)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)

export default Vue.extend({
  name: 'TacticDetail',

  components: {
    ViewHeader,
    ViewMain,
    TagList,
    InputCurrency,
    QuillEditor,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => {
    return {
      selectedChannel: {} as Channel,
      selectedType: {} as TacticType,
      selectedPlatforms: [] as TacticPlatform[],
      nonReactive: {} as any,
      shouldShowTagList: false as boolean,
      currentSelectInitiativeTypeName: '' as string,
      currentSelectInitiativeId: {} as ID,
      currentSelectInitiativeTitle: '' as string,
      shouldShowTimeInput: true as boolean,
      shouldShowEndDateInput: false as boolean,
      tagUpdateItterator: 0 as number,
      initialStartDate: '' as string,
      initialEndDate: '' as string,
      initialTacticName: '' as string,
      initialChannel: '' as string,
      initialEstimatedCost: 0 as number,
      initialActualCost: 0 as number,
      initialType: '' as string,
      initialPlatform: '' as string,
      initialRelatedInitiatives: '' as string,
      initialFieldValues: {} as {},
      updatedFieldValues: {} as {},
      initialTags: {} as {},
      initialSummary: '' as string,
      saveButtonDisabled: true as boolean,
      invalidFields: [] as string[],
      customRecurrence: {
        freq: RecurrenceFrequency['WEEKLY'],
        interval: 1,
        byweekday: RecurrenceByweekday['MO'],
        monthlyRule: '',
        recurrenceText: '',
      } as CustomRecurrence,
      customRecurrenceFields: {
        byweekday: [
          {
            value: RecurrenceByweekday['SU'],
            label: 'Sunday',
          },
          {
            value: RecurrenceByweekday['MO'],
            label: 'Monday',
          },
          {
            value: RecurrenceByweekday['TU'],
            label: 'Tuesday',
          },
          {
            value: RecurrenceByweekday['WE'],
            label: 'Wednesday',
          },
          {
            value: RecurrenceByweekday['TH'],
            label: 'Thursday',
          },
          {
            value: RecurrenceByweekday['FR'],
            label: 'Friday',
          },
          {
            value: RecurrenceByweekday['SA'],
            label: 'Saturday',
          },
        ],
        freq: [
          {
            value: RecurrenceFrequency['DAILY'],
            label: 'Days',
          },
          {
            value: RecurrenceFrequency['WEEKLY'],
            label: 'Weeks',
          },
          {
            value: RecurrenceFrequency['MONTHLY'],
            label: 'Months',
          },
          {
            value: RecurrenceFrequency['YEARLY'],
            label: 'Years',
          },
        ],
      },
      showCustomRecurrenceDialog: false as boolean,
      isCustomRecurrence: false as boolean,
      showCustomFieldImageDialog: false as boolean,
      customFieldDialogImage: '' as string,
      localTactic: {} as Tactic,
      editModeFieldIds: [] as number[],
      customImageFieldUrlsMap: [] as {
        fieldId: number | string;
        valueId: number | string,
        url: string;
      }[],
      currentOverlayHTML: '' as string,
    }
  },
  computed: {
    currentStartDate: {
      get: function (): Date {
        return this.localTactic.startDate
      },
      set: function (newValue: Date) {
        this.localTactic.startDate = newValue
      },
    },
    selectedPlatformSingle: {
      get(): TacticPlatform | null {
        return this.selectedPlatforms.length ? this.selectedPlatforms[0] : null
      },
      set(newValue: TacticPlatform){
        this.selectedPlatforms = [newValue]
      }
    },
    supportingTactics(): Tactic[] {
      if(this.localTactic.id.intID === 0){
        return []
      }
      return this.$store.getters.currentPlan.tactics.filter((tactic)=> tactic.relatedInitiatives.map((mapping: RelatedInitiativeMapping) => mapping.initiativeId.intID ).includes(this.localTactic.id.intID) )
    },
    saveMenuItems(): {}[] {
      return [
        {
          label: 'Save and Copy',
          command: (event) => {
            this.handleSaveTactic(true).then((returnedTactic)=>{
              this.$store.commit('setCurrentTacticIntID', 0)
              this.$router.push({
                path:
                  `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/${this.isInitiative ? 'initiative' : 'tactic'}/0?new=${Date.now()}&copyof=${returnedTactic.id.intID}`
              })
            })
          },
        },
        {
          label: 'Save and Add New',
          command: (event) => {
            this.handleSaveTactic(true).then((returnedTactic)=>{
              this.$store.commit('setCurrentTacticIntID', 0)
              let queryString = `new=${Date.now()}`
              if(this.$route.query && this.$route.query.datetime){
                queryString = `${queryString}&datetime=${this.$route.query.datetime}`
              }
              this.$router.push({
                path:
                  `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/${this.isInitiative ? 'initiative' : 'tactic'}/0?${queryString}`
              })
            })
          },
        }
      ]
    },

    viewTitle(): string {
      const tacticType = this.isInitiative ? 'Initiative' : 'Tactic'
      const tacticTitle = this.localTactic ? this.localTactic.title : ''
      return this.isCreateTactic ? 'New ' + tacticType : tacticTitle
    },

    isCreateTactic(): boolean {
      return this.$store.getters.currentTacticIntID == 0 ? true : false
    },
    isInitiative(): boolean {
      return this.$route.name == 'InitiativeDetail' ||
        this.$route.name == 'PlanInitiativeDetail' ||
        this.$route.name == 'AccountInitiativeDetail'
        ? true
        : false
    },
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    currentTactic(): Tactic {
      return this.localTactic
    },
    currentCategorizedTagsAssignedToTactic(): TagCategory[] {
      this.tagUpdateItterator
      const localTagList = [...this.localTactic.tags]

      // Create categories
      const categories: TagCategory[] = []
      localTagList.forEach((tag) => {
        const categoryIndex = categories.findIndex((cat)=> cat.name === tag.type)
        if (categoryIndex === -1) {
          categories.push(new TagCategory(
            categories.length + 1,
            tag.type,
          ))
        }
      })
      // Add tags to categories
      localTagList.forEach((tag) => {
        const categoryIndex = categories.findIndex((cat) => cat.name === tag.type)
        categories[categoryIndex === -1 ? 3 : categoryIndex].tags.push(tag)
      })
      // Sort categories alphabetically
      categories.sort((a, b)=>{
        if(a.name === b.name){
          return 0
        }
        return a.name > b.name ? 1 : -1
      })
      // Sort tags within categories according to orderIndex
      categories.forEach((tagCat)=>{
        tagCat.tags.sort((a, b)=>{
          if(a.orderIndex === b.orderIndex){
            return 0
          }
          return a.orderIndex > b.orderIndex ? 1 : -1
        })
      })
      return categories
    },
    channelOptions(): Channel[] {
      return this.$store.getters.currentChannels
        .filter(
          (channel) => {
            return (this.isLeadPlan && channel.isLead) || !this.isLeadPlan
          }
        )
        .sort((a, b) => {
          // Sort lead before nested
          if (a.isLead && !b.isLead) {
            return -1
          }
          if (!a.isLead && b.isLead) {
            return 1
          }
          return 0
        }).sort((a, b)=>{
          // Sort by user-defined orderIndex
          if(a.orderIndex === b.orderIndex){
            return 0
          }
          return a.orderIndex > b.orderIndex ? 1 : -1
        }).map((channel)=>{
          // Disable channel options that the user doesn't have rights for
          if(this.$store.getters.currentUserRole.canEditAllChannels === false){
            const matchingEnabledChannels = this.$store.getters.currentUserRole.channels.filter((channelId)=>channelId.intID === channel.id.intID)
            if(matchingEnabledChannels.length === 0){
              channel.isDisabled = true
            }
          }
          return channel
        })
    },
    platformOptions(): TacticPlatform[] {
      if (!this.selectedType.id) {
        return []
      }
      return this.selectedType.tacticPlatforms.filter(
        (platform) => (this.isLeadPlan && platform.isLead) || !this.isLeadPlan
      )
    },
    typeOptions(): TacticType[] {
      let returnArray = [] as TacticType[]
      if (!this.selectedChannel.id) {
        return returnArray
      }
      if(!this.selectedChannel.useMonthlyFocusType){
        returnArray = this.selectedChannel.tacticTypes.filter((type)=>type.name.toLowerCase() !== this.$store.getters.monthlyFocusTypeName)
      }else{
        returnArray = this.selectedChannel.tacticTypes
      }
      return returnArray.sort((a,b) => a.orderIndex - b.orderIndex )
    },

    combinedTacticFields(): TacticField[] {
      if(this.selectedType && this.selectedType?.tacticFields?.length){
        return this.currentPlan.tacticFields.concat(this.selectedType?.tacticFields)
      }
      return this.currentPlan.tacticFields
    },
    planLevelTacticFields(): TacticField[] {
      return this.currentPlan.tacticFields
    },
    typeLevelTacticFields(): TacticField[] {
      if(this.selectedType){
        return this.selectedType.tacticFields
      }
      return []
    },

    filteredInitiativesInCategories(): TacticType[] {
      const returnArray = [] as TacticType[]
      this.$store.getters.allInitiativesInCategories.forEach((type) => {
        const thisType = {...type}
        thisType.tactics = type.tactics.filter((initiative) => 
          (!this.isLeadPlan || (this.isLeadPlan && !initiative.isNested)) && 
          this.isInitiativeTouchingTactic(initiative)
        )
        returnArray.push(thisType)
      })
      return returnArray.sort((a,b) => a.orderIndex - b.orderIndex)
    },
    customRecurrenceMonthlyOptions(): object[] {
      const startDate = new Date(this.localTactic.startDate)
      const dtStart = this.getRecurranceFormattedStringForDate(startDate)

      return [
        {
          value: `BYMONTHDAY=${format(startDate, 'd')}`,
          label: `The ${format(startDate, 'do')}`,
        },
        {
          ...this.getMonthlyByDay(startDate, dtStart, true),
        },
      ]
    },
    repeatOptions(): object[] {
      const startDate = new Date(this.localTactic.startDate)
      const dtStart = this.getRecurranceFormattedStringForDate(startDate)

      return [
        {
          value: `${dtStart}FREQ=DAILY`,
          label: 'Daily',
        },
        {
          value: `${dtStart}FREQ=WEEKLY;WKST=SU;BYDAY=${format(
            startDate,
            'EEEEEE'
          ).toUpperCase()}`,
          label: `Weekly on ${format(startDate, 'EEEE')}`,
        },
        {
          value: `${dtStart}FREQ=MONTHLY;BYMONTHDAY=${format(
            startDate,
            'd'
          )}`,
          label: `Monthly on the ${format(startDate, 'do')}`,
        },
        {
          ...this.getMonthlyByDay(startDate, dtStart),
        },
        {
          value: `${dtStart}FREQ=YEARLY`,
          label: `Annually on ${format(startDate, 'MMM')} ${format(
            startDate,
            'd'
          )}`,
        },
        {
          value: 'custom',
          label: 'Custom',
        },
      ]
    },
    isCustomRecurrenceValid(): boolean {
      const {freq, monthlyRule, byweekday} = this.customRecurrence

      return (
        freq === RecurrenceFrequency['DAILY'] ||
        freq === RecurrenceFrequency['YEARLY'] ||
        (freq === RecurrenceFrequency['MONTHLY'] && !!monthlyRule) ||
        (freq === RecurrenceFrequency['WEEKLY'] && !!byweekday)
      )
    },
    isMonthlyOnly(): boolean {
      if (this.selectedType.name) {
        return (
          this.selectedType.name.toLowerCase() == this.$store.getters.monthlyFocusTypeName ||
          this.selectedType.name.toLowerCase() == this.$store.getters.initiativeTypeNamesDict.monthlyTheme
        )
      }
      return false
    },

    canUserEditChannel(): boolean {
      if(!this.selectedChannel.id || this.currentTactic.id.intID === 0 || this.isInitiative){
        // No channel selected OR this is initiative OR this is a new tactic
        return true
      }

      // Check channels in user role
      let hasChannelEditRights = this.$store.getters.currentUserRole.channels.find(
          (channelId) => channelId.intID === this.selectedChannel?.id?.intID
        )
          ? true
          : false
      
      if (this.$store.getters.currentUserRole.canEditAllChannels === true) {
        // User can edit all channels
        hasChannelEditRights = true
      }
          
      return hasChannelEditRights
    },
    canUserDeleteTactic(): boolean {
      return (
        this.$store.getters.currentAccountPermissionLevel <=
        Role.LEVEL_CONTRIBUTOR
      )
    },
    canUserEditFields(): boolean {
      return this.canUserEditChannel && (
        this.$store.getters.currentAccountPermissionLevel <=
        Role.LEVEL_CONTRIBUTOR
      )
    },
    canUserEditTags(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
    canUserEditBudget(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
  },
  watch: {
    selectedType() {
      if (this.isMonthlyOnly) {
        this.onMonthlyFocusDateSelect()
      }
    },
    localTactic: function () {
      if (this.localTactic.rrule && this.localTactic.isCustomRecurrence) {
        const rule = rrulestr(this.localTactic.rrule)
        this.customRecurrence = {
          ...this.customRecurrence,
          recurrenceText: rule.toText(),
        }
      }
    },
  },
  created: function() {
    //Display loading blocker until API calls return values
    this.$store.commit('updateIsUIBlocked', true)

    this.nonReactive.localFieldValues = [] as TacticFieldValue[]
    this.$store.commit('setCurrentTacticIntID', Number(this.$route.params.tacticId))
    this.localTactic = this.$store.getters.currentTactic
    this.setInitialConditionalValues()

    //Is this a new tactic?
    if (this.$store.getters.currentTacticIntID !== 0) {
      this.$store
        .dispatch('refreshCurrentTactic')
        .then(
          (returnedTactic: Tactic) => {
            this.localTactic =
              this.updateTacticWithStashedLocalPropertyValues(
                this.localTactic,
                returnedTactic
              )
            this.createPlaceholderRelatedInitiatives()
            this.updateSelectedChannelTypePlatform()
            this.updateEndDateInputVisibility()
            this.setInitialValues()
            this.nonConditionalChange()
            this.updateCustomRecurrenceValues()
            this.updateLocalFieldValues()
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error refreshing tactic.',
              detail: error,
              life: 3000,
            })
          }
        )
        .finally(() => {
          this.$store.commit('updateIsUIBlocked', false)
        })
    } else {
      // Determine date for new tactic
      let newStartDate: Date
      const dateQueryParamString = this.$route.query?.datetime as string
      if(this.$route.query?.datetime){
        newStartDate = new Date(dateQueryParamString)
        if(this.$route.query?.datetime.indexOf('T') < 0){
          newStartDate.setHours(8, 0, 0, 0)
          newStartDate.setMinutes(0)
        }
      }else{
        newStartDate = new Date()
        newStartDate.setHours(8, 0, 0, 0)
      }

      //Ensure new tactic properties are reset if creating new tactic after cancelling previously
      this.localTactic.title = ''
      this.localTactic.startDate = newStartDate
      this.localTactic.endDate = new Date(newStartDate)
      this.$store.commit('updateIsUIBlocked', false)
      this.updateSelectedChannelTypePlatform()

      // Default initiatives to all day and show end date input
      if(this.isInitiative){
        this.localTactic.isAllDay = true
        this.shouldShowEndDateInput = true
        this.shouldShowTimeInput = false
        this.localTactic.startDate.setHours(8, 0, 0, 0)
        this.localTactic.endDate.setHours(8, 0, 0, 0)
      }

      if (this.$route.query.copyof && this.$route.params.tacticId == '0') {
        this.copyTactic()
        this.saveButtonDisabled = false
      }
    }
  },
  beforeRouteLeave: function (to, from, next) {
    this.$destroy()
    next()
  },
  beforeDestroy: function () {
    this.localTactic.newFileName = ''
    this.localTactic.summary = ''
    this.localTactic = new Tactic()
    this.selectedChannel = new Channel()
    this.selectedType = new TacticType()
    this.selectedPlatforms = []
    this.currentSelectInitiativeId = new ID()
    this.nonReactive = {}
  },
  methods: {
    getRecurranceFormattedStringForDate(inputDate: Date) {
      const year = inputDate.getFullYear()
      const month =
        inputDate.getMonth() > 8
          ? inputDate.getMonth() + 1
          : '0' + (inputDate.getMonth() + 1)
      const date =
        inputDate.getDate() > 9
          ? inputDate.getDate()
          : '0' + inputDate.getDate()
      const hours =
        inputDate.getHours() > 9
          ? inputDate.getHours()
          : '0' + inputDate.getHours()
      const minutes =
        inputDate.getMinutes() > 9
          ? inputDate.getMinutes()
          : '0' + inputDate.getMinutes()
      const seconds =
        inputDate.getSeconds() > 9
          ? inputDate.getSeconds()
          : '0' + inputDate.getSeconds()

      return `DTSTART=${year}${month}${date}T${hours}${minutes}${seconds};`
    },
    getTitleForTactic(tactic: Tactic) {
      let returnString = tactic.title
      if (this.isLeadPlan && tactic.abbreviatedPlanName !== '') {
        if (returnString.length > 0) {
          returnString = tactic.abbreviatedPlanName + ' > ' + returnString
        } else {
          returnString = tactic.abbreviatedPlanName
        }
      }
      return returnString
    },
    getPlatformNamesForTactic(tactic: Tactic) {
      const tacticPlatformIntIds = tactic.tacticPlatformIds.map((id: ID) => id.intID)
      return this.$store.getters.currentTacticPlatforms.filter((platform: TacticPlatform) => tacticPlatformIntIds.includes(platform.id.intID)).map((platform: TacticPlatform) => platform.name).toString()
    },
    getInitiativeMappingForType(type: TacticType): RelatedInitiativeMapping {
      const thisMappingObj = this.localTactic.relatedInitiatives.find((mappingObj: RelatedInitiativeMapping) => mappingObj.tacticTypeId.intID === type.id.intID)
      return thisMappingObj ? thisMappingObj : new RelatedInitiativeMapping()
    },
    isInitiativeTouchingTactic(initiative: Tactic): boolean {
      const extendedStartDate = new Date(initiative.startDate)
      extendedStartDate.setDate(extendedStartDate.getDate() - 60)
      extendedStartDate.setHours(0,0,0,0)
      const extendedEndDate = new Date(initiative.endDate)
      extendedEndDate.setDate(extendedEndDate.getDate() + 60)
      extendedEndDate.setHours(23,59,59,999)
      return (this.localTactic.startDate >= extendedStartDate && this.localTactic.startDate <= extendedEndDate) || 
        (this.localTactic.endDate >= extendedStartDate && this.localTactic.endDate <= extendedEndDate)
    },
    createPlaceholderRelatedInitiatives() {
      // Create mapping object for each type of initiative
      this.filteredInitiativesInCategories.forEach((tacticType) => {
        if(!this.localTactic.relatedInitiatives.find((mappingObj) => mappingObj.tacticTypeId.intID === tacticType.id.intID)){
          this.createSinglePlaceholderRelatedInitiative(tacticType)
        }
      })
    },
    createSinglePlaceholderRelatedInitiative(tacticType): RelatedInitiativeMapping {
      const newMappingForType = new RelatedInitiativeMapping(
        ID.fromResponseObject(this.localTactic.id.intID, 'tactics'),
        ID.fromResponseObject(0, 'tactics'),
        '',
        ID.fromResponseObject(tacticType.id.clone().intID, 'tactic_types'),
        tacticType.name
      )
      this.localTactic.relatedInitiatives.push(newMappingForType)

      return newMappingForType
    },
    resetRelatedInitiatives() {
      this.localTactic.relatedInitiatives = []
      if (this.$store.getters.currentTacticIntID !== 0) {
          this.createPlaceholderRelatedInitiatives()
      }
    },
    setInitialValues() {
      this.initialTacticName = this.localTactic.title
      this.initialEstimatedCost = this.localTactic.estimatedCost
      this.initialActualCost = this.localTactic.actualCost
      this.initialStartDate = JSON.stringify(this.localTactic.startDate)
      this.initialEndDate = JSON.stringify(this.localTactic.endDate)
      this.initialChannel = JSON.stringify(this.selectedChannel)
      this.initialType = JSON.stringify(this.selectedType)
      this.initialPlatform = JSON.stringify(this.selectedPlatforms)
      this.initialSummary = JSON.stringify(this.localTactic.summary)
      this.initialRelatedInitiatives = JSON.stringify(this.localTactic.relatedInitiatives)
    },
    setInitialConditionalValues() {
      //storing the initial values to compare them later
      this.localTactic.typeValues.forEach((v) => {
        this.initialFieldValues[v.tacticFieldId.intID] = v.value
      })
      //storing the updated values === initial values to compare them at the first render
      this.localTactic.typeValues.forEach((v) => {
        this.updatedFieldValues[v.tacticFieldId.intID] = v.value
      })
      this.initialTags = [...this.localTactic.tags]
    },
    compareObjects(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)

      if (keys1.length !== keys2.length || keys1.length === 0 && keys2.length === 0) {
        this.saveButtonDisabled = false
        return false
      }

      for (const key of keys1) {
        if (object1[key] !== object2[key]) {
          this.saveButtonDisabled = false
          return false
        }
      }

      this.saveButtonDisabled = true
      return true
    },
    nonConditionalChange() {
      if (
        !this.localTactic.title ||
        this.localTactic.title === '' ||
        !this.selectedType ||
        !this.selectedType.id ||
        this.selectedType.id.intID === 0
      ) {
        this.saveButtonDisabled = true
      } else if (
        this.initialTacticName === this.localTactic.title &&
        this.initialEstimatedCost === this.localTactic.estimatedCost &&
        this.initialActualCost === this.localTactic.actualCost &&
        this.initialStartDate === JSON.stringify(this.localTactic.startDate) &&
        this.initialEndDate === JSON.stringify(this.localTactic.endDate) &&
        this.initialChannel === JSON.stringify(this.selectedChannel) &&
        this.initialType === JSON.stringify(this.selectedType) &&
        this.initialPlatform === JSON.stringify(this.selectedPlatforms) &&
        this.initialRelatedInitiatives === JSON.stringify(this.localTactic.relatedInitiatives) &&
        JSON.stringify(this.updatedFieldValues) === JSON.stringify(this.initialFieldValues) &&
        this.initialSummary === JSON.stringify(this.localTactic.summary)
      ) {
        this.saveButtonDisabled = true
      } else {
        this.saveButtonDisabled = false
      }
    },
    conditionalChange(field, val) {
      this.combinedTacticFields.forEach(() => {
        this.updatedFieldValues[field.id.intID] = val
      })
      if (
        JSON.stringify(this.updatedFieldValues) ===
        JSON.stringify(this.initialFieldValues)
      ) {
        this.saveButtonDisabled = true
      } else {
        this.saveButtonDisabled = false
      }
    },

    showCancelConfirmation(event) {
      if (this.saveButtonDisabled) {
        this.resetRecurrence()
        this.routeToMostRecentOtherView()
      } else {
        this.$confirm.require({
          acceptLabel: 'YES',
          rejectLabel: 'NO',
          target: event.currentTarget,
          message:
            'Are you sure you want to cancel? Your changes will be lost.',
          accept: () => {
            if (this.isCreateTactic) {
              this.currentTactic.tags = []
            }
            this.resetRecurrence()
            this.routeToMostRecentOtherView()
          },
          reject: () => {
            // Cancel rejected
          },
        })
      }
    },
    updateSelectedChannelTypePlatform() {
      this.currentPlan.channels.forEach((channel) => {
        channel.tacticTypes.forEach((type) => {
          if (type.id.intID === this.localTactic.tacticTypeId.intID) {
            this.selectedChannel = channel
            this.selectedType = type
            if (
              this.localTactic.tacticPlatforms?.length
            ) {
              this.selectedPlatforms =
                this.selectedType.tacticPlatforms.filter(
                  (platform) =>
                    this.localTactic.tacticPlatforms?.filter((platformOnTactic)=>platformOnTactic.id.intID === platform.id.intID).length
                )
            }
          }
        })
      })
      
      if(this.isInitiative && this.localTactic.id.intID === 0){
        this.selectedChannel = this.currentPlan.consolidatedInitiativeChannel
      }
    },
    updateEndDateInputVisibility() {
      this.shouldShowEndDateInput =
        this.localTactic.endDate.toString() !==
        this.localTactic.startDate.toString()
      this.shouldShowTimeInput = !this.localTactic.isAllDay
    },
    onChannelInputChange() {
      this.selectedType = new TacticType()
      this.selectedPlatforms = []
    },
    onTypeInputChange() {
      if (!this.isInitiative) {
        this.selectedPlatforms = []
        this.updateLocalFieldValues()
      }
    },
    onPlatformInputChange() {
      if (!this.selectedPlatforms) {
        this.selectedPlatforms = []
      }
    },
    onRepeatRuleInputChange(e) {
      if (this.localTactic.rrule === 'custom') {
        // Prevent focus error
        e.originalEvent.stopPropagation()

        // Update selected day of week in recurrence modal
        this.customRecurrence.byweekday = this.customRecurrenceFields.byweekday[this.localTactic.startDate.getDay()].value

        // Show recurrence modal
        this.showCustomRecurrenceDialog = true
        this.localTactic.isCustomRecurrence = true
      }
    },
    handleCustomRecurrenceModalClose() {
      if (this.localTactic.rrule === 'custom') {
        this.resetRecurrence()
      }
    },
    onCustomRecurrenceSubmit() {
      this.nonConditionalChange()
      const {freq, interval, monthlyRule, byweekday} = this.customRecurrence
      let rule = `FREQ=${freq};INTERVAL=${interval}`
      if (freq === 'WEEKLY') {
        rule += `;BYWEEKDAY=${byweekday}`
      }
      if (freq === 'MONTHLY') {
        rule += `;${monthlyRule}`
      }

      const rrule = rrulestr(rule, {dtstart: this.localTactic.startDate})
      this.localTactic.rrule = rrule.toString()
      this.customRecurrence.recurrenceText = rrule.toText()
      this.localTactic.isCustomRecurrence = true
      this.showCustomRecurrenceDialog = false
    },
    updateLocalFieldValues() {
      this.combinedTacticFields.forEach((field) => {
          let thisValue: TacticFieldValue = new TacticFieldValue(
            new ID(),
            new ID(field.id.intID, field.id.apiID)
          )

          this.localTactic.typeValues.find((value) => {
            if (value.tacticFieldId.intID == field.id.intID) {
              thisValue = value
            }
          })

          const existingLocalValue = this.nonReactive.localFieldValues?.find((localValue) => localValue.tacticFieldId.intID === field.id.intID)
          if(existingLocalValue){
            thisValue.value = existingLocalValue.value
          }else{
            this.nonReactive.localFieldValues.push(thisValue)
          }
          
          const existingUrlMappingForField = this.customImageFieldUrlsMap?.find((urlMapping) => urlMapping.fieldId === field.id.intID)
          if (field.type == TacticFieldType.image && !!thisValue.value && existingUrlMappingForField?.valueId != thisValue.value) {
            this.$store.getters.services.tactics
              .getMediaAsset(thisValue.value)
              .then((response) => {
                this.customImageFieldUrlsMap = this.customImageFieldUrlsMap.concat({
                  fieldId: field.id.intID,
                  valueId: thisValue.value,
                  url: response.fileLocation,
                })
              })
          }
        })
    },
    getFieldValueForFieldId(fieldId: ID): TacticFieldValue {
      this.updateLocalFieldValues()
      return this.nonReactive.localFieldValues.find(
        (value) => value.tacticFieldId.intID == fieldId.intID
      )
    },
    persistFieldValue(fieldValue: TacticFieldValue): Promise<TacticFieldValue> {
      return new Promise((resolve, reject) => {
        if (fieldValue.id.intID == 0) {
          this.$store.getters.services.tactics
            .createTacticFieldValue(fieldValue)
            .then(
              (returnedFieldValue) => {
                resolve(returnedFieldValue)
              },
              (error) => {
                reject(error)
              }
            )
        } else {
          this.$store.getters.services.tactics
            .updateTacticFieldValue(fieldValue)
            .then(
              (returnedFieldValue) => {
                resolve(returnedFieldValue)
              },
              (error) => {
                reject(error)
              }
            )
        }
      })
    },
    updateTacticWithStashedLocalPropertyValues(
      tacticToStash: Tactic,
      tacticToUpdate
    ): Tactic {
      // Stash and re-applylead/nested values because the API only populates them when requesting plan
      const returnTactic = tacticToUpdate
      returnTactic.isLead = tacticToStash.isLead
      returnTactic.isNested = tacticToStash.isNested
      returnTactic.abbreviatedPlanName = tacticToStash.abbreviatedPlanName
      returnTactic.tacticPlatforms = JSON.parse(JSON.stringify(tacticToStash.tacticPlatforms)) // Deep cloning tacticPlatforms array because TacticPlatform objects were losing their name values
      return returnTactic
    },
    async handleSaveTactic(shouldPreventRouting = false) {
      //Create/update all field values
      let shouldAbortTacticSave = false
      const newFieldValues: TacticFieldValue[] = []
      for (let i = 0; i < this.nonReactive.localFieldValues.length; i += 1) {
        await this.persistFieldValue(this.nonReactive.localFieldValues[i]).then(
          (returnedFieldValue) => {
            newFieldValues.push(returnedFieldValue)
          },
          (error) => {
            //TODO: delete value objects in newFieldValues?
            shouldAbortTacticSave = true
          }
        )
      }

      // Return a promise after create or update to suppoprt additional actions after save
      return new Promise<Tactic>((resolve, reject) => {
        this.localTactic.typeValues = newFieldValues
        if (shouldAbortTacticSave) {
          reject()
        }

        // only include rrule if repeats box is checked
        if (!this.localTactic.repeats) {
          this.localTactic.rrule = ''
        }

        //Create/update tactic
        this.localTactic.tacticTypeId = this.selectedType.id
        this.localTactic.tacticPlatforms = this.selectedPlatforms
        this.localTactic.planId = this.$store.getters.currentPlan.id
        if (this.isCreateTactic) {
          this.localTactic.creatorId = this.$store.getters.currentUser.id
          this.localTactic.isInitiative = this.isInitiative

          this.$store.getters.services.tactics
            .create(this.localTactic)
            .then((returnedTactic) => {
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: this.isInitiative
                  ? 'Initiative created.'
                  : 'Tactic created.',
                life: 3000,
              })

              // Attach associated documents
              this.localTactic.documents.forEach((document)=>{
                returnedTactic.documentIds.push(document.id)
                document.tactics.push(returnedTactic.id)
                this.$store.getters.services.documents.update(document)
              })

              if (this.$store.getters.currentPlan.plans.length) {
                // If adding to a lead plan, set isLead to true on new tactic
                returnedTactic.isLead = true
              }
              this.$store.dispatch('addTacticToCurrentPlan', returnedTactic)

              // Route back if performing a normal save (not add new or copy) of a new tactic
              if(shouldPreventRouting !== true){
                this.routeToMostRecentOtherView()
              }

              this.setInitialConditionalValues()
              this.setInitialValues()
              this.saveButtonDisabled = true
              resolve(returnedTactic)
            })
        } else {
          this.$store.getters.services.tactics
            .update(this.localTactic)
            .then((returnedTactic) => {
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: this.isInitiative
                  ? 'Initiative updated.'
                  : 'Tactic updated.',
                life: 3000,
              })
              returnedTactic = this.updateTacticWithStashedLocalPropertyValues(
                this.localTactic,
                returnedTactic
              )
              this.$store
                .dispatch('refreshCurrentTactic', returnedTactic)
                .then(this.updateCustomRecurrenceValues)

              this.setInitialConditionalValues()
              this.setInitialValues()
              this.saveButtonDisabled = true
              resolve(returnedTactic)
            })
        }
      })
    },
    handleDeleteTactic(event) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure you want to delete this tactic?',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.tactics
            .delete([this.localTactic.id.intID])
            .then(
              () => {
                this.$store.commit('updateIsUIBlocked', true)
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: 'Tactic deleted.',
                  life: 3000,
                })
                this.$store
                  .dispatch(
                    'removeTacticFromCurrentPlanById',
                    this.localTactic.id
                  )
                  .finally(() => {
                    this.$store.dispatch('refreshCurrentPlan').finally(() => {
                      this.$store.commit('updateIsUIBlocked', false)
                      this.routeToMostRecentOtherView()
                    })
                  })
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary:
                    'There was an error deleting this tactic. Please try again.',
                })
              }
            )
        },
        reject: () => {
          //Delete rejected
        },
      })
    },
    resetRecurrence() {
      // if you cancel a new tactic that has repeat values, then try
      // to create a new one again it will still have these values
      // so delete them
      if (this.localTactic.id.intID === 0) {
        this.localTactic.repeats = false
        this.localTactic.isCustomRecurrence = false
        this.localTactic.rrule = null
      }
    },
    routeToMostRecentOtherView() {
      // Navigate to the nearest route in history that isn't the TacticDetail
      let foundOtherRoute = false
      const thisRouteName = this.$route.name
      this.$store.getters.routeHistory.toReversed().forEach((route, i)=>{
        if(
          !foundOtherRoute && 
          route.name !== 'SignIn' &&
          (
            (thisRouteName === 'TacticDetail' && route.name === 'InitiativeDetail') ||
            (thisRouteName === 'TacticDetail' && route.name !== 'TacticDetail') ||
            (thisRouteName === 'InitiativeDetail' && route.name !== 'InitiativeDetail')
          )
        ){
          foundOtherRoute = true
          this.$router.go(i * -1)
        }
      })
      // Fallback if no other route is found, just go to last route in history
      if(foundOtherRoute === false){
        console.log('no other route found')
        this.$router.back()
      }
    },
    onTagListUpdate(updatedList: Tag[]) {
      this.localTactic.tags = updatedList
      this.tagUpdateItterator += 1
      this.compareObjects(this.localTactic.tags, this.initialTags)
    },
    handleRemoveTag(id: ID) {
      this.localTactic.tags = this.localTactic.tags.filter(
        (tag) => tag.id.intID != id.intID
      )
      this.compareObjects(this.localTactic.tags, this.initialTags)
    },
    onMultiDayChange() {
      if(this.shouldShowEndDateInput === true){
        this.localTactic.isAllDay = true
        this.onAllDayChange()
      }else{
        this.localTactic.endDate = new Date(
          this.localTactic.startDate.toString()
        )
      }
    },
    onAllDayChange() {
      // Reset hours/minutes/seconds if changing tactic to/from all-day
      this.localTactic.startDate.setHours(8, 0, 0, 0)
      this.localTactic.endDate.setHours(8, 0, 0, 0)

      // Manage time input visibility
      this.shouldShowTimeInput = !this.localTactic.isAllDay
    },
    onUnscheduledChange() {
      if(this.localTactic.isUnscheduled){
        // Remove start and end dates
        this.localTactic.startDate = new Date(0)
        this.localTactic.endDate = new Date(0)
        // Remove repeat
        this.localTactic.rrule = null
        this.localTactic.recurrenceText = ''
        this.localTactic.recurrenceEndDate = new Date(0)
        this.localTactic.isCustomRecurrence = false
        // Clear related initiatives selections
        this.resetRelatedInitiatives()
      }else{
        // Reset dates
        this.localTactic.startDate = new Date()
        this.localTactic.startDate.setDate(1)
        this.localTactic.startDate.setHours(8, 0, 0, 0)
        this.localTactic.endDate = new Date()
        this.localTactic.endDate.setDate(1)
        this.localTactic.endDate.setHours(8, 0, 0, 0)
      }
    },
    onRelatedInitiativeChange($event, tacticType) {
      let thisMapping = this.localTactic.relatedInitiatives.find((mapping: RelatedInitiativeMapping) => mapping.tacticTypeId.intID === tacticType.id.intID)
      if(!thisMapping){
        thisMapping = this.createSinglePlaceholderRelatedInitiative(tacticType)
      }

      if($event.value){
        const thisInitiative = this.$store.getters.currentInitiatives.find((tactic: Tactic) => tactic.id.intID === $event.value.intID)
        thisMapping.initiativeId.intID = thisInitiative ? thisInitiative.id.intID : $event.value.intID
        thisMapping.initiativeTitle = thisInitiative ? thisInitiative.title : ''
      }else{
        thisMapping.initiativeId = new ID()
        thisMapping.initiativeTitle = ''
      }
    },
    onInputChange() {
      if (!this.isMonthlyOnly) {
        if (this.shouldShowEndDateInput == false) {
          this.localTactic.endDate = new Date(
            this.localTactic.startDate.toString()
          )
        } 
        if (this.localTactic.endDate < this.localTactic.startDate) {
          this.localTactic.endDate = this.localTactic.startDate
        }
      }

      if (!this.localTactic.relatedInitiatives) {
        this.resetRelatedInitiatives()
        this.compareObjects(JSON.stringify(this.localTactic.relatedInitiatives), this.initialRelatedInitiatives)
      }

      if(this.localTactic.recurrenceEndDate){
        this.localTactic.recurrenceEndDate.setHours(23,59,59,999)
      }

      //TODO: currently using forceUpdate because some input components were not truggering DOM render, this is likely because their models are properties of currentTactic and not reactive, resolve this issue by ensuring that these models are reactive
      this.$forceUpdate()
    },
    clearRepeat() {
      this.localTactic.repeats = false
      this.localTactic.recurrenceText = ''
      this.localTactic.rrule = ''
    },
    getMonthlyByDay(
      startDate: Date,
      dtStart: string,
      onlyReturnMonthlyRule = false
    ) {
      const d = new Date(startDate.getTime())
      const dayOfWeek = d.getDay()
      const month = d.getMonth()
      const datesWithSameDayOfWeek = [] as Date[]

      // Get the first instance of the day of week in the month
      d.setDate(1)
      while (d.getDay() !== dayOfWeek) {
        d.setDate(d.getDate() + 1)
      }

      // get all instances of day of week
      while (d.getMonth() === month) {
        datesWithSameDayOfWeek.push(new Date(d))
        d.setDate(d.getDate() + 7)
      }

      // find our start date's index of those
      let indexOfStartDate = 0
      datesWithSameDayOfWeek.forEach((date, index) => {
        if (isSameDay(startDate, date)) {
          indexOfStartDate = index
        }
      })

      const isFifthInstanceOfWeekDay = indexOfStartDate > 4

      if (onlyReturnMonthlyRule) {
        return {
          value: isFifthInstanceOfWeekDay
            ? `BYSETPOS=-1;BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`
            : `BYSETPOS=${indexOfStartDate + 1};BYDAY=${format(
                startDate,
                'EEEEEE'
              ).toUpperCase()}`,
          label: isFifthInstanceOfWeekDay
            ? `Monthly on last ${format(startDate, 'eeee')} of the month`
            : `The ${indexOfStartDate + 1}${
                ['st', 'nd', 'rd'][
                  ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
                ] || 'th'
              } ${format(startDate, 'eeee')} of the month`,
        }
      }
      return {
        value: isFifthInstanceOfWeekDay
          ? `${dtStart}FREQ=MONTHLY;BYSETPOS=-1;BYDAY=${format(
              startDate,
              'EEEEEE'
            ).toUpperCase()}`
          : `${dtStart}FREQ=MONTHLY;BYSETPOS=${
              indexOfStartDate + 1
            };BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`,
        label: isFifthInstanceOfWeekDay
          ? `Monthly on last ${format(startDate, 'eeee')} of the month`
          : `Monthly on the ${indexOfStartDate + 1}${
              ['st', 'nd', 'rd'][
                ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
              ] || 'th'
            } ${format(startDate, 'eeee')} of the month`,
      }
    },
    handleEditCustomRuleClick(e) {
      e.preventDefault()
      this.showCustomRecurrenceDialog = true
    },
    handleRemoveCustomRuleClick(e) {
      e.preventDefault()
      this.localTactic.isCustomRecurrence = false
      this.localTactic.rrule = ''
      this.localTactic.recurrenceText = ''
    },
    onMonthlyFocusDateSelect() {
      // Ensure start/end dates for tactic span first and last day of the selected month
      this.localTactic.startDate.setDate(1)
      this.localTactic.endDate = new Date(this.localTactic.startDate)
      this.localTactic.endDate.setMonth(
        this.localTactic.startDate.getMonth() + 1
      )
      this.localTactic.endDate.setDate(0)
    },
    updateCustomRecurrenceValues() {
      const {rrule, isCustomRecurrence} = this.localTactic
      if (rrule && isCustomRecurrence) {
        const {startDate} = this.localTactic
        const rule = rrulestr(rrule)
        const dtStart = this.getRecurranceFormattedStringForDate(startDate)

        let freq = (RRule as any).optionsToString({
          freq: rule.options.freq,
        })
        freq = freq.substr(freq.indexOf('FREQ=') + 5)

        let monthlyRule = ''
        let byweekday = RecurrenceByweekday['MO']
        if (freq === RecurrenceFrequency['MONTHLY']) {
          const monthlyOptions = [
            {
              value: `BYMONTHDAY=${format(startDate, 'd')}`,
              label: `The ${format(startDate, 'do')}`,
            },
            {
              ...this.getMonthlyByDay(startDate, dtStart, true),
            },
          ]
          monthlyRule =
            rrule.indexOf('BYMONTHDAY') >= 0
              ? monthlyOptions[0].value
              : monthlyOptions[1].value
        } else if (freq === RecurrenceFrequency['WEEKLY']) {
          const weekRule = (RRule as any).optionsToString({
            byweekday: rule.options.byweekday,
          })
          byweekday =
            RecurrenceByweekday[weekRule.substr(weekRule.indexOf('BYDAY=') + 6)]
        }

        this.customRecurrence = {
          recurrenceText: rule.toText(),
          freq: RecurrenceFrequency[freq],
          interval: rule.options.interval,
          monthlyRule,
          byweekday,
        }
        this.localTactic.isCustomRecurrence = true
      }
    },
    openInNewWindow(url) {
      if (url.substr(0, 10).indexOf('://') == -1) {
        url = `https://${url}`
      }
      window.open(url, '_blank')
    },
    isFieldType(field, type) {
      return field.type === TacticFieldType[type]
    },
    uploadCustomFieldImage(fieldId: ID, fileData) {
      const file = new FormData()
      file.append('file', fileData.files[0])

      this.$store.getters.services.tactics
        .importCustomFieldImage(this.currentTactic.id.intID, fieldId, file)
        .then((response) => {
          const thisImageField = this.getFieldValueForFieldId(fieldId)
          thisImageField.value = response.id.intID.toString()
          this.customImageFieldUrlsMap = this.customImageFieldUrlsMap.concat({
            fieldId: fieldId.intID,
            valueId: response.id.intID,
            url: response.fileLocation,
          })
          this.$forceUpdate()
          this.conditionalChange(thisImageField, thisImageField.value)
        })
    },
    removeCustomFieldImage(fieldId: ID) {
      const thisImageField = this.getFieldValueForFieldId(fieldId)
      thisImageField.value = ''
      this.customImageFieldUrlsMap = this.customImageFieldUrlsMap.filter(
        (imageField) => imageField.fieldId != fieldId.intID
      )
      this.conditionalChange(thisImageField, thisImageField.value)
    },
    getCustomImageFieldUrl(fieldId) {
      // TODO: utf
      const val = this.customImageFieldUrlsMap.find(
        (imageUrlMapping) => imageUrlMapping.fieldId == fieldId.intID
      )
      return val ? val.url : ''
    },
    copyTactic() {
      this.$store.getters.services.tactics
        .get([this.$route.query.copyof])
        .then((response: Tactic) => {
          response.title = `Copy of ${response.title}`
          this.localTactic = response
          
          this.updateSelectedChannelTypePlatform()
          this.updateEndDateInputVisibility()
          this.updateLocalFieldValues()

          //Apply tags from tag IDs
          this.localTactic.tags = this.$store.getters.currentPlan.tags.filter((tag) => this.localTactic.tagIds.map((id)=>id.intID).includes(tag.id.intID))
        })
    },
    updateCurrentTacticStartDate(newStartDate) {
      this.localTactic.startDate = newStartDate
      this.nonConditionalChange()
      this.$forceUpdate()
    },
    updateCurrentTacticEndDate(newEndDate) {
      this.localTactic.endDate = newEndDate
      this.nonConditionalChange()
    },
    isFieldUrlType(field) {
      return field.type === TacticFieldType.url
    },
    updateEditModeFieldIds(id: number) {
      if (this.editModeFieldIds.includes(id)) {
        this.editModeFieldIds = this.editModeFieldIds.filter(
          (fieldId) => fieldId != id
        )
      } else {
        this.editModeFieldIds = this.editModeFieldIds.concat([id])
      }
    },
    getInitiativeForId(id: ID) {
      const thisInitiative = this.$store.getters.currentInitiatives.find(
        (tactic) => tactic.id.intID == id.intID
      )
      return thisInitiative ? thisInitiative : new Tactic()
    },
    onEditorTextChange(event, refString) {
      if(event.htmlValue.length > 999900 && event.htmlValue.length <= 1000000){
        // @ts-expect-error
        document.getElementById(refString).innerHTML = '<p class="warning-message">Content in this field is approaching the 1MB limit.</p>'
      }else if(event.htmlValue.length > 1000000){
        // @ts-expect-error
        document.getElementById(refString).innerHTML = '<p class="error-message">Content in this field has exceeded the 1MB limit, some content will be lost.</p>'
      }else{
        // @ts-expect-error
        document.getElementById(refString).innerHTML = ''
      }
    },
    deleteDocument(event, documentId, tactic: Tactic) {
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this document?`,
        target: event.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.documents.delete(documentId).then(() => {
            tactic.documents = tactic.documents.filter(
              (doc) => doc.id.intID !== documentId
            )
            this.$store.getters.currentTactic.documents = this.$store.getters.currentTactic.documents.filter(
              (doc) => doc.id.intID !== documentId
            )
          })
          // TODO: also delete MediaAsset?
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    onFileUpload(e, tactic: Tactic) {
      tactic.newFile = e.target.files[0]
      tactic.newFileName = e.target.files[0].name
    },
    uploadFile(tactic) {
      const newFile = new FormData()

      if (!tactic.newFile) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must upload file first',
        })
        return
      }

      const file = new File([tactic.newFile], tactic.newFileName, {
        type: tactic.newFile.type,
        lastModified: tactic.newFile.lastModified,
      })
      newFile.append('file', file)

      this.$store.getters.services.users
        .mediaAsset(newFile)
        .then((newAsset) => {
          const newPlanDocument = PlanDocument.fromResponseObject({
            asset: newAsset,
            plan: this.currentPlan.id.intID,
          })
          if(tactic.id.intID !== 0){
            newPlanDocument.tactics = [tactic.id]
          }
          this.$store.getters.services.documents
            .create(newPlanDocument)
            .then((newDBPlanDocument) => {
              newPlanDocument.id = ID.fromResponseObject(
                newDBPlanDocument.id,
                'plan_documents'
              )
              // Add document to local tactic and to plan
              tactic.documents.push(newPlanDocument)
              this.currentPlan.documents.push(newPlanDocument)

              if(tactic.id.intID !== 0){
                this.$store.getters.services.tactics
                .update(tactic)
                .then((newDBTactic) => {
                  tactic.newFile = ''
                  tactic.newFileName = ''

                  // TODO: Consider revising: This ensures that new documents are displayed when this tactic is reloaded during the same session, but that should be possible by simply updating or replacing the tactic in AppStore
                  this.$store.dispatch('refreshCurrentPlan')
                })
              }
            })
        })
        .catch(() => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary:
              'There was an error uploading the file, try with a smaller file',
          })
        })
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.edit-link {
  @include font-light;
  font-size: 1.1rem;
  background: #ffffff;
  padding: 0.8rem 1.2rem;
  border: 0.1rem solid #707070;
}
.underline {
  text-decoration: underline;
}

.tactic-detail-wrapper {
  .tag-list-column {
    flex-grow: 1;
  }
  .tag-chip {
    padding: 0.7rem 0.7rem 0.7rem 1.6rem;
    border-radius: 1.6rem;
    background-color: $white-smoke;
    color: $night-rider;
    font-size: 1.1rem;
    line-height: 1.6rem;

    .p-button {
      padding: 0;

      .pi {
        font-size: 1.2rem;
      }
    }
  }
  input:disabled,
  .p-component.p-disabled,
  .p-checkbox-box.p-disabled {
    opacity: 1;
    border-color: $nobel;
    border-style: dashed;
  }

  .custom-field-image {
    width: 200px;
  }
}

.tactic-detail-documents-container {
  .p-datatable-thead {
    display: none;
  }
  .p-datatable-tbody {
    border-top: 0.1rem solid rgba(0, 0, 0, 0.08);

    .p-button-text {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .upload-document-container {
    padding: 2rem 0;
    column-gap: 1rem;
  }
}

.custom-recurrence-dialog-body {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
}

.custom-recurrence-number-input {
  .p-inputnumber-input {
    width: 4rem;
    margin: 0 0.5rem;
    text-align: center;
  }
}

.custom-properties-container {
  .custom-field-url-link {
    color: #333333;
    text-decoration: underline;
  }
}

.custom-field-image-controls {
  position: relative;

  .custom-field-image-controls-container {
    position: absolute;
    top: 5px;
    right: 5px;

    button {
      height: 25px;
      width: 25px;
      background: white;
      margin-left: 5px;

      &:hover {
        background: white !important;
      }
    }
  }
}

.custom-image-dialog {
  img {
    max-width: 100%;
  }

  button {
    position: absolute;
    top: 20px;
    right: 25px;
  }
}

.tag-list-column {
  div.is-lead {
    display: flex;
    column-gap: 1rem;

    &::before {
      content: '\e925'; // pi-home
      display: block;
      font-family: 'primeicons';
      font-size: 1.6rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
    }
  }
}
.related-initiatives-container {
  display: none;

  &:has(.initiative-item-container) {
    display: block
  }
}
.initiative-item {
  &.is-lead {
    display: flex;
    column-gap: 1rem;

    &::before {
      content: '\e925'; // pi-home
      display: block;
      font-family: 'primeicons';
      font-size: 1.6rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
    }
  }
}
.p-editor-container.disable-editor {
  // Workaround for Editor component not being disabled (via readonly attribute) when canUserEditFields is false
  pointer-events: none;
}

#btn_tactic_save {
  .p-menu-overlay {
    left: auto !important;
    right: 0 !important;
  }
}

.p-datatable-wrapper span.is-lead {
  display: flex;
  column-gap: 1rem;

  &::before {
    content: '\e925'; // pi-home
    display: block;
    font-family: 'primeicons';
    font-size: 1.6rem;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
  }
}
</style>