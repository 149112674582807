<template>
  <div class="tag-list-container">
    <template v-if="allowList">
      <div class="p-d-flex p-jc-between">
        <div class="p-mr-6"  v-for="(tagCategory, i) in $store.getters.currentPlanTagCategories" :key="i">
          <h3>{{tagCategory.name}}</h3>
          <div
            class="p-d-flex p-ai-center p-mb-2"
            v-for="(tag) of tagCategory.tags"
            :key="'tag_' + tag.id.intID"
          >
            <template v-if="(isLeadPlan && !tag.isNested) || !isLeadPlan">
              <Checkbox
                v-if="allowSelect"
                :id="'tag_' + tag.id.intID"
                :value="tag.id.intID"
                v-model="localSelectedTagIntIds"
                class="p-mr-3"
              />
              <label
                :for="'tag_' + tag.id.intID"
                :class="{
                  'is-nested': tag.isNested,
                  'is-lead': tag.isLead,
                }"
              >
                <template v-if="isLeadPlan && tag.isNested">
                  {{ tag.abbreviatedPlanName }} > {{ tag.title }}
                </template>
                <template v-else>
                  {{ tag.title }}
                </template>
              </label>
            </template>
          </div>
        </div>
      </div>
    </template>
    <div v-if="allowCreate">
      <h3 v-if="allowList">Create New Tag</h3>
      <div class="p-field">
        <label for="newTagType">Type</label>
        <SelectButton
          id="newTagType"
          v-model="selectedTypeOption"
          :options="typeOptions"
          :multiple="false"
          class="p-mb-4"
        />
      </div>
      <div class="p-d-flex p-jc-between p-ai-center">
        <div class="p-field p-mr-4">
          <label for="newTag">Name</label>
          <InputText
            id="newTag"
            type="text"
            v-model="newTag.text"
            autocomplete="off"
          />
        </div>
        <Button @click="createTag">Add Tag</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import Tag, {TagType} from '@/models/Tag'
import ID from '@/models/ID'
import { isThisHour } from 'date-fns'
import Plan from '@/models/Plan'
import TagCategory from '@/models/TagCategory'

Vue.component('Checkbox', Checkbox)
Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('SelectButton', SelectButton)

export default Vue.extend({
  name: 'TagList',
  props: {
    allowSelect: Boolean,
    allowEdit: Boolean,
    allowCreate: Boolean,
    allowList: Boolean,
    selectedTags: Array as () => Tag[],
    skipRefetch: Boolean,
  },
  data: () => {
    return {
      newTag: {} as Tag,
      currentEditingTags: [] as Tag[],
      localSelectedTagIntIds: [] as number[],
      typeOptions: [
        TagType.Objective,
        TagType.TargetSegment,
        TagType.JourneyPhase,
        TagType.Custom,
      ] as string[],
      selectedTypeOption: TagType.Objective as string,
    }
  },
  computed: {
    isLeadPlan(): boolean {
      return this.currentPlan.plans.length ? true : false
    },
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    tags(): Tag[] {
      return this.currentPlan.tags
    },
    tagCategories(): TagCategory[] {
      return this.$store.getters.currentPlanTagCategories
    }
  },
  watch: {
    localSelectedTagIntIds() {
      this.$emit('updateSelection', [
        ...this.tags.filter(
          (tag) => this.localSelectedTagIntIds.indexOf(tag.id.intID) > -1
        ),
      ])
    },
  },
  mounted: function () {
    if (this.allowCreate) {
      this.newTag = new Tag()
    }
    if (this.allowSelect) {
      this.localSelectedTagIntIds = [
        ...this.selectedTags.map((tag) => tag.id.intID),
      ]
    }
 
  },
  methods: {
    isEditingTag(tag: Tag): boolean {
      return this.currentEditingTags.find(
        (checkTag) => checkTag.id.intID == tag.id.intID
      )
        ? true
        : false
    },
    editTag(tag: Tag) {
      if (this.isEditingTag(tag)) {
        this.updateTag(tag)
      } else {
        this.currentEditingTags.push(tag)
      }
    },
    cancelEditTag(tag: Tag) {
      this.currentEditingTags.splice(this.currentEditingTags.indexOf(tag), 1)
    },
    createTag() {
      if (this.selectedTypeOption && this.selectedTypeOption !== 'Custom') {
        this.newTag.text = this.selectedTypeOption + ': ' + this.newTag.text
      }
      this.newTag.planId = new ID(
        this.currentPlan.id.intID,
        this.currentPlan.id.apiID
      )
      this.$store.getters.services.tags
        .create(this.newTag)
        .then((returnedTag) => {
          this.newTag = new Tag()

          if (this.skipRefetch) {
            this.$emit('addTag', returnedTag)
          } else {
            this.$store.dispatch('refreshCurrentPlan')
          }
        })
    },
    updateTag(tag: Tag) {
      this.$store.getters.services.tags.update(tag).then((returnedTag) => {
        this.cancelEditTag(tag)
      })
    },
    deleteTag(tag: Tag) {
      this.$store.getters.services.tags.delete([tag.id.intID]).then(() => {
        this.$store.dispatch('refreshCurrentPlan')
      })
    },
  },
})
</script>

<style lang="scss">
.tag-list-container {
  label.is-lead {
    display: flex;
    column-gap: 1rem;

    &::before {
      content: '\e925'; // pi-home
      display: block;
      font-family: 'primeicons';
      font-size: 1.6rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
    }
  }
}
</style>
