<template>
  <Sidebar
    :visible.sync="localShowHide"
    :baseZIndex="100"
    position="right"
    class="p-sidebar-md tactic-filters-wrapper"
  >
    <div class="tactic-filters-header p-d-flex p-jc-between p-ai-center p-mr-6">
      <h2>Filters</h2>
      <Button
        v-if="$store.getters.activeFiltersCount"
        label="Clear Filters"
        @click="onClearFiltersClick"
        :badge="$store.getters.activeFiltersCount"
      />
    </div>
    <Accordion
      :multiple="true"
      class="widgets-accordion"
      :activeIndex="accordionActiveIndicies"
      @tab-open="onAccordionTabOpen"
      @tab-close="onAccordionTabClose"
    >
      <AccordionTab header="Nested Plans" v-if="currentPlan.plans.length">
        <template #default>
          <div
            v-for="(plan, index) of currentNestedPlans"
            :key="plan.key"
            class="p-field-checkbox p-d-flex p-jc-between"
          >
            <label :for="'nested_plan_' + index">{{ plan.name }}</label>
            <InputSwitch
              :id="'nested_plan_' + index"
              v-model="visibleNestedPlansObject[plan.id.intID]"
              @input="onInputSwitchInput($event, plan.id.intID)"
            />
          </div>
        </template>
      </AccordionTab>
      <AccordionTab header="Lead Plan" v-if="currentPlan.parentId.intID !== 0">
        <template #default>
          <div class="p-field-checkbox p-d-flex p-jc-between">
            <label for="lead_plan_hidden">{{ currentLeadPlan.name }}</label>
            <InputSwitch id="lead_plan_hidden" v-model="isLeadPlanVisible" />
          </div>
        </template>
      </AccordionTab>
      <AccordionTab header="Channels" :disabled="!currentChannelsSorted.length">
        <template #default>
          <Accordion
            :multiple="false"
            class="channels-accordion"
          >
            <AccordionTab
              v-for="(channel, index) of currentChannelsSorted"
              :key="channel.key"
              class="channel-accordion-tab"
            >
              <template #header>
                <div class="p-field-checkbox channel-checkbox">
                  <Checkbox
                    :id="'channel_' + index"
                    :value="channel"
                    :name="channel.name"
                    v-model="checkedChannels"
                    @change="onChannelCheckboxChange($event, channel)"
                  />
                  <label
                    :for="'channel_' + index"
                    :class="{
                      'is-lead': channel.isLead,
                      'is-nested': channel.isNested,
                    }"
                  >
                    <template v-if="isLeadPlan && channel.isNested">
                      {{ channel.abbreviatedPlanName }} > {{ channel.name }}
                    </template>
                    <template v-else>
                      {{ channel.name }}
                    </template>
                  </label>
                  <div class="channel-selected-types-count" v-if="getSelectedTypesForChannel(channel).length">
                    {{ getSelectedTypesForChannel(channel).length }} / {{ channel.tacticTypes.length }}
                  </div>
                </div>
              </template>
              <template #default>
                <template v-for="(type, tIndex) of channel.tacticTypes">
                  <div
                    v-if="type.name.toLowerCase() !== $store.getters.monthlyFocusTypeName || (type.name.toLowerCase() === $store.getters.monthlyFocusTypeName && channel.useMonthlyFocusType)"
                    :key="type.key"
                    class="p-field-checkbox type-checkbox"
                  >
                    <Checkbox
                      :id="'channel_' + index + '_type_' + tIndex"
                      :value="type"
                      :name="type.name"
                      v-model="selectedTacticTypes"
                      @change="onCheckboxChange"
                    />
                    <label :for="'channel_' + index + '_type_' + tIndex">
                      {{ type.name }}
                    </label>
                  </div>
                </template>
              </template>
            </AccordionTab>
          </Accordion>
        </template>
      </AccordionTab>
      <AccordionTab
        header="Initiatives"
        :disabled="totalCategorizedInitiatives > 0 ? false : true"
      >
        <template #default>
          <div
            v-for="(type, i) in categorizedInitiatives"
            :key="'category_' + i"
          >
            <div :class="{'p-mt-5': i != 0}" class="p-mb-3">
              {{ type.name }}
            </div>
            <template
              v-for="(initiative, index) of type.tactics"
            >
              <div
                :key="initiative.key"
                class="p-field-checkbox"
                v-if=isTacticTouchingMonth(initiative)
              >
                <Checkbox
                  :id="'initiative_' + type.name + '_' + index"
                  :value="initiative"
                  :name="initiative.title"
                  v-model="selectedInitiatives"
                  @change="onCheckboxChange"
                />
                <label
                  :for="'initiative_' + type.name + '_' + index"
                  :class="{
                    'is-nested': initiative.isNested,
                    'is-lead': initiative.isLead,
                  }"
                >
                  <template v-if="isLeadPlan && initiative.isNested">
                    {{ initiative.abbreviatedPlanName }} > {{ initiative.title }}
                  </template>
                  <template v-else>
                    {{ initiative.title }}
                  </template>
                </label>
              </div>
            </template>
          </div>
        </template>
      </AccordionTab>
      <AccordionTab
        header="Tags"
        :disabled="$store.getters.currentTags.length ? false : true"
      >
        <template #default>
          <div
            v-for="(tagCategory, tagCategoryIndex) in $store.getters.currentPlanTagCategories"
            :key="'tag_category_' + tagCategoryIndex"
          >
            <div :class="{'p-mt-5': tagCategoryIndex != 0}" class="p-mb-3">
              {{ tagCategory.name }}
            </div>
            <div 
              v-for="(tag, tagIndex) of tagCategory.tags" 
              :key="tag.key"
              >
              <div class="p-field-checkbox">
                <Checkbox
                  :id="'tag_' + tagCategoryIndex + '_' + tagIndex"
                  :value="tag"
                  :name="tag.title"
                  v-model="selectedTags"
                  @change="onCheckboxChange"
                />
                <label
                  :for="'tag_' + tagCategoryIndex + '_' + tagIndex"
                  :class="{
                    'is-lead': tag.isLead,
                    'is-nested': tag.isNested,
                  }"
                >
                  <template v-if="isLeadPlan && tag.isNested">
                    {{ tag.abbreviatedPlanName }} > {{ tag.title }}
                  </template>
                  <template v-else>
                    {{ tag.title }}
                  </template>
                </label>
              </div>
            </div>
          </div>
        </template>
      </AccordionTab>
      <AccordionTab
        header="Calendars of Interest"
        v-if="shouldShowCalendarsOfInterest"
        :disabled="!$store.getters.currentSubscriptions.length" 
      >
        <template #default>
          <div
            v-for="(sub, index) of $store.getters.currentSubscriptions"
            :key="sub.key"
            class="p-field-checkbox"
          >
            <Checkbox
              :id="'subscription_' + index"
              :value="sub"
              :name="sub.name"
              v-model="selectedSubscriptions"
            />
            <label :for="'subscription_' + index">{{ sub.name }}</label>
          </div>
        </template>
      </AccordionTab>
    </Accordion>
  </Sidebar>
</template>

<script lang="ts">
import Vue from 'vue'
import Checkbox from 'primevue/checkbox'
import Sidebar from 'primevue/sidebar'
import Inplace from 'primevue/inplace'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Badge from 'primevue/badge'
import Button from 'primevue/button'
import CustomView from '@/models/CustomView'
import Channel from '@/models/Channel'
import Tactic from '@/models/Tactic'
import Tag from '@/models/Tag'
import Subscription from '@/models/Subscription'
import Panel from 'primevue/panel'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Plan from '@/models/Plan'
import TagCategory from '@/models/TagCategory'
import TacticType from '@/models/TacticType'

Vue.component('Checkbox', Checkbox)
Vue.component('Sidebar', Sidebar)
Vue.component('Inplace', Inplace)
Vue.component('InputText', InputText)
Vue.component('InputSwitch', InputSwitch)
Vue.component('Button', Button)
Vue.component('Panel', Panel)
Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)
Vue.component('Badge', Badge)

export default Vue.extend({
  name: 'TacticFilters',
  props: {
    showHide: Boolean,
  },
  data: () => {
    return {
      localSelectedTacticTypes: [] as TacticType[],
      localSelectedInitiatives: [] as Tactic[],
      localSelectedTags: [] as Tag[],
      localSelectedSubscriptions: [] as Subscription[],
    }
  },
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    currentLeadPlan(): Plan {
      if (this.currentPlan.parentId.intID !== 0) {
        return this.$store.getters.allPlans.find(
          (plan) => plan.id.intID == this.currentPlan.parentId.intID
        )
      }
      return {} as Plan
    },
    currentNestedPlans(): Plan[] {
      return this.$store.getters.allPlans.filter((plan) =>
        this.currentPlan.plans.find((planId) => planId.intID == plan.id.intID)
          ? true
          : false
      )
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    currentLeadPlanFilterKey(): string {
      return 'lpl-' + this.$store.getters.currentPlanIntID.toString()
    },
    currentNestedPlansFilterKey(): string {
      return 'npl-' + this.$store.getters.currentPlanIntID.toString()
    },
    currentTacticTypesFilterKey(): string {
      return 'tp-' + this.$store.getters.currentPlanIntID.toString()
    },
    currentInitiativesFilterKey(): string {
      return 'in-' + this.$store.getters.currentPlanIntID.toString()
    },
    currentTagsFilterKey(): string {
      return 'tg-' + this.$store.getters.currentPlanIntID.toString()
    },
    currentSubscriptionsFilterKey(): string {
      return 'sb-' + this.$store.getters.currentPlanIntID.toString()
    },
    localShowHide: {
      get(): boolean {
        return this.showHide
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('shouldHide')
        }
      },
    },
    currentTagsSorted(): Tag[] {
      const localCurrentTags = [...this.$store.getters.currentTags]
      return localCurrentTags.sort((a, b) => {
        const nameA = a.text.toUpperCase()
        const nameB = b.text.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
    currentChannelsSorted(): Channel[] {
      const localCurrentChannels = [...this.$store.getters.currentChannels]
      return localCurrentChannels
        .sort((a, b) => {
          if (a.isLead && !b.isLead) {
            return -1
          }
          if (!a.isLead && b.isLead) {
            return 1
          }
          return 0
        })
        .sort((a, b) => {
          if (a.orderIndex === b.orderIndex) {
            return 0
          }
          return a.orderIndex > b.orderIndex ? 1 : -1
        })
    },
    isLeadPlanVisible: {
      get(): boolean {
        return this.$store.getters.isLeadPlanVisible
      },
      set(newValue: boolean) {
        this.$store.commit('updateIsLeadPlanVisible', newValue)
        window.localStorage.setItem(
          this.currentLeadPlanFilterKey,
          this.$store.getters.isLeadPlanVisibleQueryString
        )
      },
    },
    visibleNestedPlanIntIds: {
      get(): number[] {
        return this.$store.getters.visibleNestedPlansIntIds
      },
      set(newValue: number[]) {
        if (newValue.length == 0) {
          this.$store.commit('updateVisibleNestedPlansIntIds', [])
          window.localStorage.setItem(
            this.currentNestedPlansFilterKey,
            ''
          )
          this.$store.commit('isNestedPlanFilterActive', false)
        } else {
          this.$store.commit('updateVisibleNestedPlansIntIds', newValue)
          window.localStorage.setItem(
            this.currentNestedPlansFilterKey,
            this.$store.getters.visibleNestedPlansQueryString
          )
          this.$store.commit('isNestedPlanFilterActive', true)
        }
      },
    },
    visibleNestedPlansObject(): {} {
      const returnObj = {}
      this.currentNestedPlans.forEach((plan) => {
        returnObj[plan.id.intID] = this.visibleNestedPlanIntIds?.includes(
          plan.id.intID
        )
      })
      return returnObj
    },

    checkedChannels: {
      get(): Channel[] {
        if(this.$store.getters.selectedChannels.length == this.$store.getters.currentChannels.length){
          return []
        }
        return this.$store.getters.selectedChannels
      },
      set(channels: Channel[] ){
        // do nothing
      }
    },
    selectedTacticTypes: {
      get(): TacticType[] {
        if (this.localSelectedTacticTypes.length) {
          return this.$store.getters.selectedTacticTypes
        }
        return []
      },
      set(newValue: TacticType[]) {
        this.localSelectedTacticTypes = newValue
        if (newValue.length == 0) {
          this.$store.commit(
            'updateSelectedTacticTypes',
            this.$store.getters.currentTacticTypes
          )
          window.localStorage.removeItem(this.currentTacticTypesFilterKey)
          this.$store.commit('isChannelFilterActive', false)
        } else {
          this.$store.commit('updateSelectedTacticTypes', newValue)
          window.localStorage.setItem(
            this.currentTacticTypesFilterKey,
            this.$store.getters.selectedTacticTypesQueryString
          )
          this.$store.commit('isChannelFilterActive', true)
        }
        this.manageSubscriptionFilters()
      },
    },
    selectedInitiatives: {
      get(): Tactic[] {
        if (this.localSelectedInitiatives.length) {
          return this.$store.getters.selectedInitiatives
        }
        return []
      },
      set(newValue: Tactic[]) {
        this.localSelectedInitiatives = newValue
        if (newValue.length == 0) {
          this.$store.commit(
            'updateSelectedInitiatives',
            this.$store.getters.currentInitiatives
          )
          window.localStorage.removeItem(this.currentInitiativesFilterKey)
          this.$store.commit('isInitiativeFilterActive', false)
        } else {
          this.$store.commit('updateSelectedInitiatives', newValue)
          window.localStorage.setItem(
            this.currentInitiativesFilterKey,
            this.$store.getters.selectedInitiativesQueryString
          )
          this.$store.commit('isInitiativeFilterActive', true)
        }
        this.manageSubscriptionFilters()
      },
    },
    categorizedInitiatives(): TacticType[] {
      return this.$store.getters.allInitiativesInCategories.filter((type: TacticType) => {
        let hasTacticsInDateRange = false
        type.tactics.forEach((tactic: Tactic) => {
          if(this.isTacticTouchingMonth(tactic)){
            hasTacticsInDateRange = true
          }
        })
        return hasTacticsInDateRange
      })
    },
    totalCategorizedInitiatives(): number {
      return this.categorizedInitiatives.length
    },
    selectedTags: {
      get(): Tag[] {
        if (this.localSelectedTags.length) {
          return this.$store.getters.selectedTags
        }
        return []
      },
      set(newValue: Tag[]) {
        this.localSelectedTags = newValue
        if (newValue.length == 0) {
          this.$store.commit(
            'updateSelectedTags',
            this.$store.getters.currentTags
          )
          window.localStorage.removeItem(this.currentTagsFilterKey)
          this.$store.commit('isTagFilterActive', false)
        } else {
          this.$store.commit('updateSelectedTags', newValue)
          window.localStorage.setItem(
            this.currentTagsFilterKey,
            this.$store.getters.selectedTagsQueryString
          )
          this.$store.commit('isTagFilterActive', true)
        }
        this.manageSubscriptionFilters()
      },
    },
    selectedSubscriptions: {
      get(): Subscription[] {
        if (this.localSelectedSubscriptions.length) {
          return this.$store.getters.selectedSubscriptions
        }
        return []
      },
      set(newValue: Subscription[]) {
        this.localSelectedSubscriptions = newValue
        this.manageSubscriptionFilters()
      },
    },

    accordionActiveIndicies(): number[] {
      const returnArray = [] as number[]
      this.$store.getters.currentDateRange //Hack to force computed property update when date range changes
      if (
        window.localStorage.getItem(
          'tf-tactic-types-' + this.$store.getters.currentPlanIntID.toString()
        ) != 'false' &&
        this.$store.getters.currentTacticTypes.length
      ) {
        returnArray.push(0)
      }
      if (
        window.localStorage.getItem(
          'tf-initiatives-' + this.$store.getters.currentPlanIntID.toString()
        ) != 'false' &&
        this.totalCategorizedInitiatives
      ) {
        returnArray.push(1)
      }
      if (
        window.localStorage.getItem(
          'tf-strategy-' + this.$store.getters.currentPlanIntID.toString()
        ) != 'false' &&
        this.$store.getters.currentTags.length
      ) {
        returnArray.push(2)
      }
      if (
        window.localStorage.getItem(
          'tf-subscriptions-' + this.$store.getters.currentPlanIntID.toString()
        ) != 'false' &&
        this.$store.getters.currentSubscriptions.length
      ) {
        returnArray.push(3)
      }
      return returnArray
    },

    shouldShowCalendarsOfInterest(): boolean {
      return this.$route.name == 'Table' ? false : true
    },
  },
  watch: {
    localShowHide() {
      this.updateFiltersFromGlobalStore()
    },
    localSelectedTacticTypes(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
    localSelectedInitiatives(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
    localSelectedTags(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
    localSelectedSubscriptions(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
    visibleNestedPlanIntIds(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
    isLeadPlanVisible(){
      this.$store.dispatch('updateActiveFiltersCount')
    },
  },
  methods: {
    getSelectedTypesForChannel(channel: Channel): TacticType[] {
      const typeIdsInChannel = channel.tacticTypes.map((type) => type.id.intID)
      return this.selectedTacticTypes.filter((type) => typeIdsInChannel.includes(type.id.intID))
    },
    isTacticTouchingMonth(tactic: Tactic): boolean {
      if (
        tactic.endDate < this.$store.getters.currentDateRange[0] ||
        tactic.startDate > this.$store.getters.currentDateRange[1]
      ) {
        return false
      }
      return true
    },
    onInputSwitchInput(value, planIntId) {
      if (value) {
        this.visibleNestedPlanIntIds = [
          ...this.visibleNestedPlanIntIds,
          planIntId,
        ]
      } else {
        this.visibleNestedPlanIntIds = this.visibleNestedPlanIntIds.filter(
          (existingPlanIntId) => existingPlanIntId !== planIntId
        )
      }
      this.$forceUpdate()
    },
    onCheckboxChange($event) {
      $event.preventDefault()
      $event.stopPropagation()
    },
    onChannelCheckboxChange($event, channel: Channel) {
      this.onCheckboxChange($event)

      const selectedTypeIntIds = this.selectedTacticTypes.map((type) => type.id.intID)
      const channelTypeIntIds = channel.tacticTypes.map((type) => type.id.intID)
      if(channel.tacticTypes.filter((type) => selectedTypeIntIds.includes(type.id.intID)).length){
        this.selectedTacticTypes = this.selectedTacticTypes.filter((type) => !channelTypeIntIds.includes(type.id.intID))
      }else{
        this.selectedTacticTypes = this.selectedTacticTypes.concat(channel.tacticTypes)
      }
    },
    isCustomViewForCurrentPlan(customView: CustomView) {
      return customView.viewConfig
        .slice(0, customView.viewConfig.indexOf('?'))
        .indexOf(this.$store.getters.currentPlanIntID.toString()) > -1
        ? true
        : false
    },
    updateFiltersFromGlobalStore() {
      if (this.$store.getters.isChannelFilterActive) {
        this.localSelectedTacticTypes = this.$store.getters.selectedTacticTypes
      }
      if (this.$store.getters.isInitiativeFilterActive) {
        this.localSelectedInitiatives = this.$store.getters.selectedInitiatives
      }
      if (this.$store.getters.isTagFilterActive) {
        this.localSelectedTags = this.$store.getters.selectedTags
      }
      if (this.$store.getters.isSubscriptionFilterActive) {
        this.localSelectedSubscriptions = this.$store.getters.selectedSubscriptions
      }
    },
    onClearFiltersClick() {
      this.isLeadPlanVisible = true
      this.visibleNestedPlanIntIds = this.$store.getters.currentPlan.plans.length ? this.currentNestedPlans.map((plan) => plan.id.intID) : [this.$store.getters.currentPlan.id.intID]
      this.selectedTacticTypes = []
      this.selectedInitiatives = []
      this.selectedTags = []
      this.selectedSubscriptions = []
    },
    manageSubscriptionFilters() {
      if (
        this.$store.getters.isChannelFilterActive == true ||
        this.$store.getters.isInitiativeFilterActive == true ||
        this.$store.getters.isTagFilterActive == true
      ) {
        //Non-subscription filters are active
        if (this.$store.getters.isSubscriptionFilterActive == true) {
          this.setSubscriptionFilters(this.localSelectedSubscriptions)
        } else {
          this.setSubscriptionFilters([])
        }
      } else {
        //Non-subscription filters are NOT active
        if (this.$store.getters.isSubscriptionFilterActive == true) {
          if (this.localSelectedSubscriptions.length == 0) {
            this.clearSubscriptionFilters()
          } else {
            this.setSubscriptionFilters(this.localSelectedSubscriptions)
          }
        } else {
          this.setSubscriptionFilters(this.localSelectedSubscriptions)
        }
      }
    },
    clearSubscriptionFilters() {
      this.$store.commit('updateSelectedSubscriptions', [])
      window.localStorage.removeItem(this.currentSubscriptionsFilterKey)
      this.$store.commit('isSubscriptionFilterActive', false)
    },
    setSubscriptionFilters(newValue: Subscription[]) {
      this.$store.commit('updateSelectedSubscriptions', newValue)
      window.localStorage.setItem(
        this.currentSubscriptionsFilterKey,
        this.$store.getters.selectedSubscriptionsQueryString
      )
      this.$store.commit('isSubscriptionFilterActive', true)
    },
    getAccordionTabNameForIndex(index: number): string {
      // TODO: update indicies if lead or nested plan
      switch (index) {
        case 0:
          return 'channels'
        case 1:
          return Channel.CHANNEL_NAME_INITIATIVES.toLowerCase()
        case 2:
          return 'strategy'
        case 3:
          return 'subscriptions'
        default:
          return ''
      }
    },
    onAccordionTabOpen(event) {
      window.localStorage.setItem(
        'tf-' +
          this.getAccordionTabNameForIndex(event.index) +
          '-' +
          this.$store.getters.currentPlanIntID.toString(),
        'true'
      )
    },
    onAccordionTabClose(event) {
      window.localStorage.setItem(
        'tf-' +
          this.getAccordionTabNameForIndex(event.index) +
          '-' +
          this.$store.getters.currentPlanIntID.toString(),
        'false'
      )
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.tactic-filters-wrapper {
  max-width: 28rem;

  .tactic-filters-header {
    padding: 1.6rem 1.6rem 1.6rem 1rem;
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }
  }

  .p-panel {
    border-top: 0.2rem solid $white-smoke;

    .p-panel-header,
    .p-panel-content {
      border: 0;
      background-color: transparent;
    }
    .p-panel-header {
      padding: 1.2rem 1rem;
    }
    .p-panel-title {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
  label.is-lead {
    display: flex;

    &::before {
      content: '\e925'; // pi-home
      display: inline-block;
      padding-right: 0.6rem;
      font-family: 'primeicons';
      font-size: 1.2rem;
      line-height: 1.8rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
    }
  }

  .channel-accordion-tab {
    margin-bottom: 1rem;

    .p-accordion-header {
      .p-accordion-header-link {
        padding: 0;
        border: 0;
        background-color: #fff !important;
        font-size: 1.1rem;
      }
      &.p-highlight {
        .p-checkbox {
          .p-checkbox-box {
            border-color: #000;
          }
        }
      }
    }
    .p-accordion-content {
      padding-bottom: 0;

      .p-field-checkbox {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .p-field-checkbox {
      margin-bottom: 0;
    }

    .channel-selected-types-count {
      margin-left: 1rem;
    }
  }
}

.p-sidebar.p-sidebar-active {
  overflow-y: scroll;
}

.tactic-filters-wrapper {
  .p-accordion-header {
    .p-accordion-header-link {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0.8rem 1.6rem 0.8rem 0.8rem;
      border-color: #f9f9f9 !important;
      background-color: #f9f9f9 !important;
    }
    .p-accordion-header-link,
    .p-accordion-header-text {
      font-size: 1.4rem;
      line-height: 1.75rem;
    }
  }
  .p-accordion-content {
    border-color: white;
  }
  .p-inputswitch {
    transform: scale(0.75);

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $tacticColorPR;
      }
    }
    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background-color: $tacticColorPRBkg;
      }
    }
  }
}
</style>