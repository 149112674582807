<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Performance"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
          @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <div class="p-field">
          <div class="p-d-flex top-items">
            <p class="plan-settings-instructions">
              Centralize your performance data so insights are at the ready to inform tactical planning. Embed Looker Studio dashboards, reports built in Google Slides, PDFs, and other data sources for access within a Performance view.
            </p>
            <template v-if="useDashboardsOn">
              <p class="plan-settings-instructions" style="max-width: 50rem;">
                For a PDF: <br/>
                Enter a name for the data source. Select the PDF to embed. Click (+) Performance Data to embed.
              </p>
              <p class="plan-settings-instructions" style="max-width: 50rem;">
                For HTML: <br/>
                Enter a name for the data source. Copy the HTML embed code for the data source and paste it in the designated box. Click (+) Performance Data to embed.
              </p>
              <p class="plan-settings-instructions">
                <a class="text-link" href="https://annumplanning.com/resources/guides/embed-performance-data/" target="_blank">View detailed instructions</a>.
              </p>
              <p class="plan-settings-instructions-small">
                <strong>To set the optimal size for your embedded data sources:</strong> we recommended the following settings: width = 960, height = 569. These measurements can be modified in the embed code and can be adjusted up or down to fit your specific screen size.
              </p>
            </template>
            <HowItWorks 
              imagePath="how-it-works-dashboards.jpg" 
              slideshowPath="https://docs.google.com/presentation/d/e/2PACX-1vSuRCokdnMBomqR3KFHW6pHO6k1sap53HNPrrUeW0IFXA0p9qo7yUHWZwqIn5O7xJz1TsZ7SbzHRaw_/embed?start=false&loop=false&delayms=3000"
              :hideOverlay="true"
            />
          </div>
        </div>

        <div class="setup-checkboxes">
          <p class="checkboxes-text-first">Performance Feature:</p>
          <div class="checkbox-container">
            <Checkbox
              id="dashboardOn"
              :binary="true"
              v-model="useDashboardsOn"
              class="p-mr-2 checkbox"
              @change="() => handleToggleUseDashboards(true)"
            />
            <label for="dashboardOn">On</label>
          </div>
          <div class="checkbox-container">
            <Checkbox
              id="dashboardOff"
              :binary="true"
              v-model="useDashboardsOff"
              class="p-mr-2 checkbox"
              @change="() => handleToggleUseDashboards(false)"
            />
            <label for="dashboardOff">Off</label>
          </div>
        </div>

        <template v-if="useDashboardsOn">
          <div class="category-section">
            <Container
              @drop="(dropResults) => onCategoryReorder(dropResults, filteredCategoriesActive)"
              drag-handle-selector=".drag-icon--dashboard-category"
            >
              <Draggable
                v-for="category in filteredCategoriesActive"
                :key="category.key"
              >
              <div class="p-d-flex" style="gap: 1rem;">
                <span class="drag-icon drag-icon--dashboard-category">&#x2630;</span>
                <DataTable
                  class="active-dashboards-table" 
                  :value="category.activeDashboards"
                  dataKey="key" 
                  :key="category.key"
                  :autoLayout="true"
                  editMode="row" 
                  :editingRows.sync="activeEditingRows" 
                  @row-edit-init="onDashboardRowEditInit"
                  @row-edit-save="onDashboardRowEditSave"
                  @row-reorder="onDashboardRowReorder"
                >
                  <Header 
                    :id="`${category.key}-header`"
                    class="category-name-header"
                  >
                    <div>
                      <h2
                        v-if="!editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                      >
                        {{ category.name }}
                      </h2>
                      <template 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                      >
                        <InputText 
                          v-model="category.name" 
                          />
                      </template>
                    </div>
                     <div>
                      <Button 
                        v-if="!editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-pencil" 
                        class="p-button-text"
                        @click="onCategoryNameEditInit(category)" 
                      />
                      <Button 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-check" 
                        class="p-button-text"
                        @click="onCategoryNameEditSave(category)" 
                      />
                      <Button 
                        v-if="editingCategoryNames.filter((obj) => obj.id === category.id.intID).length"
                        icon="pi pi-times" 
                        class="p-button-text"
                        @click="onCategoryNameEditCancel(category)" 
                      />
                     </div>
                  </Header>
                  <Column 
                    rowReorder 
                    v-if="canUserEditPlanProperties"
                  />
                  <Column 
                    field="name" 
                    bodyClass="name-column"
                  >
                    <template #body="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        <div>
                          {{ slotProps.data.name }}
                          <template v-if="$store.getters.currentUser.isSuperUser">
                            <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template #editor="slotProps">
                      <div class="p-d-flex p-jc-start p-ai-center" style="gap: 4rem;">
                        <InputText v-model="slotProps.data.name" />
                        <template v-if="slotProps.data.embedHtml">
                          <Textarea v-model="slotProps.data.embedHtml" />
                        </template>
                        <template v-else>
                          <template v-if="slotProps.data.asset">
                            <div class="p-d-flex p-ai-center">
                              <div>{{ slotProps.data.asset.name }}</div>
                              <div class="add-button p-ml-2" @click="handleClearAsset(slotProps.data)">
                                <i class="pi pi-times-circle"></i>
                              </div>
                            </div>
                          </template>
                          <template v-else-if="slotProps.data.newFile">
                            <div class="dashboard-selected-file-name p-d-flex p-ai-center">
                              <div>{{ slotProps.data.newFile?.files[0]?.name }}</div>
                              <div class="add-button p-ml-2" @click="handleClearFile(slotProps.data)">
                                <i class="pi pi-times-circle"></i>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <label class="dashboard-select-file" for="update-dashboard-asset">
                              Replace PDF File
                              <input 
                                type="file" 
                                id="update-dashboard-asset" 
                                @change="onFileUploadDialog($event, slotProps.data)" 
                                hidden 
                                :disabled="!shouldAllowFormInput" 
                              />
                            </label>
                          </template>
                        </template>
                      </div>
                    </template>
                  </Column>
                  <Column 
                    :rowEditor="true" 
                    v-if="canUserEditPlanProperties"
                  ></Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-folder" 
                        severity="warning" 
                        class="p-button-text"
                        @click="onActiveDashboardArchive($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-folder" 
                        severity="warning" 
                        class="p-button-text"
                        disabled
                      />
                    </template>
                  </Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        @click="onDashboardDelete($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        disabled
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </Draggable>
          </Container>
            
            <div class="add-dashboard">
              <div class="add-dashboard-row p-d-flex p-jc-center">
                <div class="p-d-flex p-mr-3">
                  <RadioButton
                    id="newDashboardType_PDF"
                    name="newDashboardType"
                    value="pdf"
                    v-model="newDashboardType"
                    :disabled="!canUserEditPlanProperties"
                    class="p-mr-2"
                  />
                  <label for="newDashboardType_PDF">PDF</label>
                </div>
                <div class="p-d-flex">
                  <RadioButton
                    id="newDashboardType_HTML"
                    name="newDashboardType"
                    value="html"
                    v-model="newDashboardType"
                    :disabled="!canUserEditPlanProperties"
                    class="p-mr-2"
                  />
                  <label for="newDashboardType_HTML">HTML</label>
                </div>
              </div>
              <div class="add-dashboard-row p-d-flex p-jc-center">
                <div class="field">
                  <label for="dashboard-category-add" style="display: block;">Category</label>
                  <Dropdown
                    id="dashboard-category-add"
                    v-model="newDashboard.category"
                    optionLabel="name"
                    :options="categoriesOptions"
                    dataKey="key"
                    @change="() => (isSelectedCategory = true)"
                    :disabled="!shouldAllowFormInput"
                  />
                </div>
                <div
                  class="field"
                  v-if="shouldShowNewCategoryNameField"
                >
                  <label for="new-category-name">New Category Name</label>
                  <InputText
                    id="new-category-name"
                    class="new-category-name"
                    v-model="newCategoryName"
                    :disabled="!shouldAllowFormInput"
                  />
                </div>
                <div class="field">
                  <label for="new-dashboard-name">Data Source Name</label>
                  <InputText
                    id="new-dashboard-name"
                    class="new-dashboard-name"
                    v-model="newDashboard.name"
                    :disabled="!shouldAllowFormInput"
                  />
                </div>
                <div v-if="newDashboardType === 'html'" class="field">
                  <label for="new-dashboard-embed-html">HTML Embed Code</label>
                  <Textarea
                    id="new-dashboard-embed-html"
                    class="new-dashboard-embed-html"
                    v-model="newDashboard.embedHtml"
                    :disabled="!shouldAllowFormInput"
                  />
                </div>
                <div v-if="newDashboardType === 'pdf'" class="field">
                  <label>PDF File</label>
                  <template v-if="newDashboard.newFile">
                    <div class="dashboard-selected-file-name p-d-flex p-ai-center">
                      <div>{{ newDashboard.newFile?.files[0]?.name }}</div>
                      <div class="add-button p-ml-2" @click="handleClearFile(newDashboard)">
                        <i class="pi pi-times-circle"></i>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <label class="dashboard-select-file" for="new-dashboard-asset">
                      Select File
                      <input 
                        type="file" 
                        id="new-dashboard-asset" 
                        @change="onFileUploadDialog($event, newDashboard)" 
                        hidden 
                        accept=".pdf"
                        :disabled="!shouldAllowFormInput" 
                      />
                    </label>
                  </template>
                </div>
                <div class="add-button p-mt-5 p-ml-4" @click="handleAddDashboard">
                  <i class="pi pi-plus-circle p-mr-1"></i>
                  <div>Performance Data</div>
                </div>
              </div>
            </div>
          </div>

          <div class="archive-section">
            <p v-if="doesPlanHaveActiveDashboards" class="plan-settings-instructions" style="margin-top: 3.6rem; margin-bottom: 3.6rem;">Click the folder icon to the right of the data sources above to archive.</p>
            <template v-if="doesPlanHaveArchiveDashboards">
              <div class="archive-header">
                <h2>Archive <i class="pi pi-folder p-ml-4"></i></h2>
              </div>
              <template v-for="category in filteredCategoriesArchive">
              <DataTable
                  class="active-dashboards-table" 
                  :value="category.archivedDashboards" 
                  dataKey="key" 
                  :key="category.key"
                  :autoLayout="true"
                  editMode="row" 
                  :editingRows.sync="archiveEditingRows" 
                  @row-edit-init="onDashboardRowEditInit"
                  @row-edit-save="onDashboardRowEditSave"
                  @row-reorder="onDashboardRowReorder"
                >
                  <Header>
                    <h2>{{ category.name }}</h2>
                  </Header>
                  <Column 
                    rowReorder 
                    v-if="canUserEditPlanProperties"
                  />
                  <Column 
                    field="name" 
                    bodyClass="name-column"
                  >
                    <template #body="slotProps">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        <div>
                          {{ slotProps.data.name }}
                          <template v-if="$store.getters.currentUser.isSuperUser">
                            <span class="id-value"> ({{ slotProps.data.id.intID }})</span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template #editor="slotProps">
                      <div class="p-d-flex p-jc-start p-ai-center" style="gap: 4rem;">
                        <InputText v-model="slotProps.data.name" />
                        <template v-if="slotProps.data.embedHtml">
                          <Textarea v-model="slotProps.data.embedHtml" />
                        </template>
                        <template v-else>
                          <template v-if="slotProps.data.asset">
                            <div class="p-d-flex p-ai-center">
                              <div>{{ slotProps.data.asset.name }}</div>
                              <div class="add-button p-ml-2" @click="handleClearAsset(slotProps.data)">
                                <i class="pi pi-times-circle"></i>
                              </div>
                            </div>
                          </template>
                          <template v-else-if="slotProps.data.newFile">
                            <div class="dashboard-selected-file-name p-d-flex p-ai-center">
                              <div>{{ slotProps.data.newFile?.files[0]?.name }}</div>
                              <div class="add-button p-ml-2" @click="handleClearFile(slotProps.data)">
                                <i class="pi pi-times-circle"></i>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <label class="dashboard-select-file" for="update-archived-dashboard-asset">
                              Replace PDF File
                              <input 
                                type="file" 
                                id="update-archived-dashboard-asset" 
                                @change="onFileUploadDialog($event, slotProps.data)" 
                                hidden 
                                :disabled="!shouldAllowFormInput" 
                              />
                            </label>
                          </template>
                        </template>
                      </div>
                    </template>
                  </Column>
                  <Column 
                    :rowEditor="true" 
                    v-if="canUserEditPlanProperties"
                  ></Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-arrow-up" 
                        severity="warning" 
                        class="p-button-text"
                        @click="onArchivedDashboardUnarchive($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-arrow-up" 
                        severity="warning" 
                        class="p-button-text"
                        disabled
                      />
                    </template>
                  </Column>
                  <Column 
                    v-if="canUserEditPlanProperties"
                  >
                    <template #body="slotProps">
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        @click="onDashboardDelete($event, slotProps.data)" 
                      />
                    </template>
                    <template #editor>
                      <Button 
                        icon="pi pi-trash" 
                        severity="danger" 
                        class="p-button-text p-button-danger"
                        disabled
                      />
                    </template>
                  </Column>
                </DataTable>
              </template>
            </template>
          </div>
        </template>

      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import InputSwitch from 'primevue/inputswitch'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Dialog from 'primevue/dialog'
import Role from '@/models/Role'
import ID from '@/models/ID'
import DataTable from 'primevue/datatable/DataTable'
import PlanDashboard from '@/models/PlanDashboard'
import PlanDashboardCategory from '@/models/PlanDashboardCategory'
import Textarea from 'primevue/textarea/Textarea'
import {Container, Draggable} from 'vue-smooth-dnd'
import {applyDrag} from '@/utils/applyDrag'
import HowItWorks from '@/components/HowItWorks.vue'
import RadioButton from 'primevue/radiobutton'
import MediaAsset from '@/models/MediaAsset'

Vue.component('Dialog', Dialog)
Vue.component('InputSwitch', InputSwitch)
Vue.component('Textarea', Textarea)
Vue.component('DataTable', DataTable)
Vue.component('RadioButton', RadioButton)

export default Vue.extend({
  name: 'PlanSettingsDashboards',
  components: {
    ViewHeader,
    ViewMain,
    Container,
    Draggable,
    HowItWorks,
  },
  props: {
    shouldShow: Boolean,
  },
  data: () => {
    return {
      newDashboard: {} as PlanDashboard,
      newCategoryName: '' as string,
      newDashboardCategory: {} as PlanDashboardCategory,
      shouldAllowFormInput: true as boolean,
      isSelectedCategory: false as boolean,
      activeEditingRows: [],
      archiveEditingRows: [],
      editingCategoryNames: [] as {id, originalName}[],
      newDashboardType: 'pdf' as string,
    }
  },
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    canUserManagePlanUsersChannelsSubscriptions(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserEditPlanProperties(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    useDashboardsOn: {
      get: function (): boolean {
        return this.plan.useDashboards
      },
      set: function (newValue: boolean) {
        return newValue
      },
    },
    useDashboardsOff: {
      get: function (): boolean {
        return !this.plan.useDashboards
      },
      set: function (newValue: boolean) {
        return newValue
      },
    },
    filteredCategoriesActive(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.filter((category)=>{
        return category?.dashboards.filter((dashboard)=>{
          return !dashboard.isArchived
        }).length > 0
      }).sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    },
    doesPlanHaveActiveDashboards(): boolean {
      return this.filteredCategoriesActive.length > 0
    },
    filteredCategoriesArchive(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.filter((category)=>{
        return category?.dashboards.filter((dashboard)=>{
          return dashboard.isArchived
        }).length > 0
      }).sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
    },
    doesPlanHaveArchiveDashboards(): boolean {
      return this.filteredCategoriesArchive.length > 0
    },
    categoriesOptions(): PlanDashboardCategory[] {
      return this.plan.planDashboardCategories.concat([new PlanDashboardCategory(new ID(0), '[ New Category ]')])
    },
    shouldShowNewCategoryNameField(): boolean {
      return this.newDashboard?.category?.id?.intID === 0
    }
  },
  mounted() {
    this.resetNewDashboardObjects()
  },
  methods: {
    onCategoryNameEditInit(category: PlanDashboardCategory) {
      this.editingCategoryNames.push({
        id: category?.id.intID,
        originalName: category?.name
      })
    },
    onCategoryNameEditSave(category: PlanDashboardCategory) {
      this.$store.getters.services.dashboards.updateCategory(category)
        .then(
          (updatedCategory) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: 'Category updated.',
              life: 3000,
            })
            this.onCategoryNameEditCancel(category, false)
          }, 
          (error)=>{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error reordering categories.',
              detail: error.message,
            })
            this.onCategoryNameEditCancel(category)
          }
        )
    },
    onCategoryNameEditCancel(category: PlanDashboardCategory, shouldResetName = true) {
      if(shouldResetName){
        category.name = this.editingCategoryNames.find((obj) => obj.id === category?.id.intID)?.originalName
      }
      this.editingCategoryNames = this.editingCategoryNames.filter((obj) => obj.id !== category?.id.intID)
    },
    onCategoryReorder(dropResult, categoriesArray){
      const sortedCategories = applyDrag(categoriesArray, dropResult)
      sortedCategories.forEach((category, index) => {
        category.sortOrder = index
        this.$store.getters.services.dashboards.updateCategory(category).catch((error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error reordering categories.',
            detail: error.message,
          })
        })
      })
    },
    onDashboardDelete(event, dashboard: PlanDashboard) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure you want to delete this performance data?',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.dashboards.delete(dashboard.id).then(
            ()=>{
              this.$store.dispatch('refreshCurrentPlan')

              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: 'Performance data deleted.',
                life: 3000,
              })
            },
            (error)=>{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary:
                  'There was an error deleting the performance data. Please try again.',
              })
            }
          )
        },
        reject: () => {
          //Delete rejected
        },
      })
    },
    onActiveDashboardArchive(event, dashboard: PlanDashboard) {
      dashboard.isArchived = true
      this.$store.getters.services.dashboards.update(dashboard).then(
        (updatedDashboard) => {
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Performance data archived.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error archiving performance data.',
            detail: error.message,
          })
        }
      )
    },
    onArchivedDashboardUnarchive(event, dashboard: PlanDashboard) {
      dashboard.isArchived = false
      this.$store.getters.services.dashboards.update(dashboard).then(
        (updatedDashboard) => {
          this.$store.dispatch('refreshCurrentPlan')

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Performance data unarchived.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error unarchiving performance data.',
            detail: error.message,
          })
        }
      )
    },
    onDashboardRowEditInit() {
      // Init row edit
    },
    onDashboardRowEditSave(event) {
      const thisDashboard = PlanDashboard.fromResponseObject({
        id: event.newData.id.intID,
        name: event.newData.name,
        embedHtml: event.newData.embedHtml,
        asset: event.newData.asset,
        isArchived: event.newData.isArchived,
        plan: event.newData.plan.intID,
        category: event.newData.category.forRequestObject(),
        orderIndex: event.newData.sortOrder,
        newFile: event.newData.newFile,
      })

      // Prevent upload if no file is entered
      if(!thisDashboard.embedHtml && !thisDashboard.newFile && !thisDashboard.asset){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please add a PDF or embed HTML.',
        })
        return
      }

      this.uploadNewDashboardFile(thisDashboard).then(
        (newMediaAsset) => {
          this.$store.getters.services.dashboards.update(thisDashboard).then(
          (updatedDashboard) => {
            this.$store.dispatch('refreshCurrentPlan')

            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: 'Performance data updated.',
              life: 3000,
            })
          },
          (error)=>{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error updating performance data.',
              detail: error.message,
            })
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error uploading new PDF.',
            detail: error.message,
          })
        }
      )
    },
    onDashboardRowReorder(event) {
      event.value.forEach((dashboard, index) => {
        dashboard.sortOrder = index
        this.$store.getters.services.dashboards.update(dashboard).catch((error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error reordering performance data.',
            detail: error.message,
          })
        })
      })
    },
    handleToggleUseDashboards(value) {
      this.plan.useDashboards = value

      // Persist change
      this.plan.accountId = this.$store.getters.currentAccount.id
      this.$store.getters.services.plans
        .update(this.plan)
        .then((plan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Plan updated.',
            life: 3000,
          })
          this.$store.dispatch('updateCurrentPlan', plan)
        })
    },
    onFileUploadDialog(event, thisDashboard: PlanDashboard) {
      thisDashboard.newFile = event.target
    },
    handleClearFile(thisDashboard: PlanDashboard) {
      thisDashboard.newFile = null
    },
    handleClearAsset(thisDashboard: PlanDashboard) {
      thisDashboard.asset = null
    },
    async handleAddDashboard() {
      // Prevent upload if no file is entered
      if(!this.newDashboard.embedHtml && !this.newDashboard.newFile){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please add a PDF or embed HTML.',
        })
        return
      }

      // Prevent upload if new category name is not completed
      if(!this.isSelectedCategory || (this.isSelectedCategory && this.newDashboard.category?.id.intID === 0 && this.newCategoryName === '')){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please select or create a category.',
        })
        return
      }

      if(!this.shouldAllowFormInput){
        return
      }

      this.shouldAllowFormInput = false
      this.isSelectedCategory = false

      if(this.newCategoryName !== ''){
        this.newDashboardCategory.name = this.newCategoryName

        this.$store.getters.services.dashboards.createCategory(this.newDashboardCategory).then(
          (returnedDashboardCategory) => {
            this.plan.planDashboardCategories.push(returnedDashboardCategory)
            this.newDashboard.category = returnedDashboardCategory

            this.createDashboard().then(
              () => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: `Performance data created.`,
                  life: 3000,
                })
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: `Error creating performance data.`,
                })
              }
            ).finally(() => {
              this.shouldAllowFormInput = true
            })
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error creating category.`,
            })
          }
        ).finally(() => {
          this.shouldAllowFormInput = true
        })
      }else{
        this.createDashboard().then(
          () => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: `Performance data created.`,
              life: 3000,
            })
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error creating performance data.`,
            })
          }
        ).finally(() => {
          this.shouldAllowFormInput = true
        })
      }
    },
    async uploadNewDashboardFile(thisDashboard: PlanDashboard) {
      return new Promise((resolve, reject) => {
        if(thisDashboard.newFile){
          const newFile = new FormData()
          newFile.append(
            'file',
            new File(
              [thisDashboard.newFile?.files[0]], 
              thisDashboard.newFile?.files[0]?.name, 
              {
                type: thisDashboard.newFile?.files[0]?.type,
                lastModified: thisDashboard.newFile?.files[0]?.lastModified,
              }
            )
          )

          this.$store.getters.services.users
            .mediaAsset(newFile)
            .then(
              (newRawAsset) => {
                const newMediaAsset = MediaAsset.fromResponseObject(newRawAsset)
                thisDashboard.asset = newMediaAsset
                thisDashboard.newFile = null
                resolve(newMediaAsset)
              },
              (error) => {
                reject(error)
              }
            )
        }else{
          resolve(null)
        }
      })
    },
    async createDashboard() {
      return new Promise((resolve, reject) => {
        this.uploadNewDashboardFile(this.newDashboard).then(
          (newMediaAsset) => {
            this.$store.getters.services.dashboards.create(this.newDashboard)
              .then(
                (returnedNewDashboard) => {
                  this.plan.dashboards.push(returnedNewDashboard)
                  this.resetNewDashboardObjects()
                  this.$store.dispatch('refreshCurrentPlan')
                  resolve(returnedNewDashboard)
                },
                (error) => {
                  reject(error)
                }
              )
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    resetNewDashboardObjects() {
      this.newDashboard = PlanDashboard.fromResponseObject({})
      this.newDashboard.plan = this.plan.id
      this.newDashboard.newFile = null
      this.newDashboard.asset = null
      this.newCategoryName = ''
      this.newDashboardCategory = PlanDashboardCategory.fromResponseObject({})
    },
  },
})
</script>

<style lang="scss">
.dashboard-select-file {
  padding: 0.9rem !important;
  border: 1px inset #000;
}
.dashboard-selected-file-name {
  padding: 1rem 0 !important;

  .add-button {
    transform: none;
  }
}
</style>