<template>
  <div class="how-it-works" :class="{'hide-overlay': hideOverlay}">
    <div class="thumb-container">
      <h3>How It Works</h3>
      <button
        @click="
          () => {
            this.isSlideshowModalVisible = true
          }
        "
      >
        <img
          class="thumb"
          :src="require('@/assets/' + imagePath)"
          alt=""
          width="120"
          height="68"
        />
      </button>
    </div>

    <Dialog :visible.sync="isSlideshowModalVisible" :modal="isModal" :closable="true">
      <iframe
        class="slideshow-iframe"
        :src="slideshowPath"
        frameborder="0"
        width="1440"
        height="839"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        title="How It Works"
      ></iframe>
    </Dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Dialog from 'primevue/dialog'

Vue.component('Dialog', Dialog)

export default Vue.extend({
  name: 'HowItWorks',
  props:{
    imagePath: {
      type: String,
      default: ''
    },
    slideshowPath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    },
    hideOverlay: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isSlideshowModalVisible: false as boolean,
  }),
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';
.how-it-works {
  width: 100%;
  display: flex;
  justify-content: center;

  &.hide-overlay {
    .p-dialog-mask.p-component-overlay {
      background-color: transparent;
      --maskbg: transparent;
    }
  }
  .thumb-container {
    text-align: center;
  }
  h3 {
    @include font-mulish-light;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
  button {
    appearance: none;
    border: 0.1rem solid #ccc;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
  .thumb {
    max-width: 12rem;
  }
  .p-dialog {
    width: 100%;
    margin: 0 1.6rem;
    aspect-ratio: 1.6625;
  }
  .p-dialog-header {
    justify-content: flex-end;
  }
  .p-dialog-header-close {
    .p-dialog-header-close-icon {
      right: 5px;
    }
  }
  .p-dialog-content {
    padding: 1.6rem;
  }
  .slideshow-iframe {
    max-width: 100%;
    height: auto;
    aspect-ratio: 1.6625;
  }
}
</style>
