import PlanDashboard from '@/models/PlanDashboard'
import PlanDashboardCategory from '@/models/PlanDashboardCategory'
import axios from 'axios'

const DASHBOARD_PATH = 'plan_dashboards'
const DASHBOARD_CATEGORIES_PATH = 'plan_dashboard_categories'

export default class DashboardsService {
  public get(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${DASHBOARD_PATH}/${id}`).then(
        (response) => {
          resolve(PlanDashboard.fromResponseObject(response.data))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public create(newDashboard: PlanDashboard) {
    return new Promise((resolve, reject) => {
      axios
        .post(DASHBOARD_PATH, {...newDashboard.forRequestObject(), id: null})
        .then(
          (response) => {
            resolve(PlanDashboard.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  public update(updatedDashboard: PlanDashboard) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${DASHBOARD_PATH}/${updatedDashboard.id.intID}`, updatedDashboard.forRequestObject())
        .then(
          (response) => {
            resolve(PlanDashboard.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  public delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${DASHBOARD_PATH}/${id.intID}`).then(
        (response) => {
          resolve(PlanDashboard.fromResponseObject(response.data))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public createCategory(newDashboardCategory: PlanDashboardCategory) {
    return new Promise((resolve, reject) => {
      axios
        .post(DASHBOARD_CATEGORIES_PATH, {...newDashboardCategory.forRequestObject()})
        .then(
          (response) => {
            resolve(PlanDashboardCategory.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  public updateCategory(updatedCategory: PlanDashboardCategory) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${DASHBOARD_CATEGORIES_PATH}/${updatedCategory.id.intID}`, updatedCategory.forRequestObject())
        .then(
          (response) => {
            resolve(PlanDashboardCategory.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

}
